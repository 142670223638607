import React from 'react';

const Books = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="19.84" height="17.75" viewBox="0 0 19.84 17.75">
      <defs>
        <style type="text/css" jsx>
          {`.cls-1 {fill: #181818;fill-rule: evenodd;}`}
        </style>
      </defs>
      <path className="cls-1" d="M1244.3,708.6c-0.57-1.6-1.78-.867-1.43-1.008l-2.52,1.008v-1.314a0.719,0.719,0,0,0-.65-0.658h-9.86a0.6,0.6,0,0,0-.66.658v16.43a0.6,0.6,0,0,0,.66.657h9.86a0.6,0.6,0,0,0,.65-0.657v-11.83s3.14,8.9,3.82,10.812a1.069,1.069,0,0,0,1.44.361s2.23-.89,3.01-1.206a1,1,0,0,0,.28-1.423Zm-3.79,1.262,2.59-1.069,1.01,2.695-2.35,1.016Zm-1.47,2.024h-3.29v-3.943h3.29v3.943Zm-4.6-3.943v3.943h-3.95v-3.943h3.95Zm-3.95,5.258h3.95v9.858h-3.95V713.2Zm5.26,0h3.29v9.858h-3.29V713.2Zm6.46,0.388,2.45-.933,3.06,8.347-2.45.994Z" transform="translate(-1229.19 -706.625)" />
    </svg>

  );
};

export default Books;