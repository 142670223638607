const course_data = [
  {
    id: 1,
    img: 'course-01.jpg',
    duration: '4 Weeks',
    course_outline:'Online + Onsite',
    level: 'Advanced',
    title: 'Starting SEO as your Home Based Business',
    rating: '4.9',
    rating_count: 8,
    course_price: '49.00',
    lesson: 13,
    student: 28,
    category:'Art & Design',
    short_desc: 'Lorem ipsum dolor sit amet consectur adipiscing elit sed eiusmod tempor.',
    instructor: 'Jane Seymour',
    instructor_img:'team-01.webp',
    instructor_title:'Founder & CEO',
    instructor_desc:'Consectetur adipisicing elit, sed do eiusmod tempor incididunt labore et dolore magna aliqua enim minim veniam quis nostrud exercitation ulla mco laboris nisi ut aliquip ex ea commodo consequat. duis aute irure dolor in reprehenderit in voluptate.',
    features: [
      'Build fully-fledged websites and web apps for your business.',
      'After the course you will be able to build ANY website you want.',
      'You will master both front and back-end development technologies.'
    ], 
    social_links:[
      {link: 'http://facebook.com', target: '_blank', icon: 'icon-facebook'},
      {link: 'http://twitter.com', target: '_blank', icon: 'icon-twitter'},
      {link: 'https://www.linkedin.com/company/ujuzi-fin/', target: '_blank', icon: 'icon-linkedin2'},
      {link: 'https://www.youtube.com/@UJUZI2023/', target: '_blank', icon: 'icon-youtube'}
    ],
    language: 'English',
    certificate: 'yes',
    videoId: 'PICj5tr9hcc',
    course_desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor inc idid unt ut labore et dolore magna aliqua enim ad minim veniam, quis nostrud exerec tation ullamco laboris nis aliquip commodo consequat duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur enim ipsam.',
    course_desc_2: 'Excepteur sint occaecat cupidatat non proident sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium totam rem aperiam.',
    learn_list: ['Learn to use Python professionally, learning both Python 2 & Python 3!', 'Build 6 beautiful real-world projects for your portfolio (not boring toy apps)', 'Understand the Theory behind Vue.js and use it in Real Projects', 'Create responsive, accessible, and beautiful layouts'],
    course_desc_3: 'Consectetur adipisicing elit, sed do eiusmod tempor inc idid unt ut labore et dolore magna aliqua enim ad minim veniam quis nostrud exerec tation ullamco laboris nis aliquip commodo consequat duis aute irure dolor.',
    curriculum_desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor inc idid unt ut labore et dolore magna aliqua enim ad minim veniam, quis nostrud exerec tation ullamco laboris nis aliquip commodo consequat.',
    course_lessons: [
      {
        title: 'Week 1-4',
        text: 'Advanced story telling techniques for writers: Personas, Characters & Plots',
        lessons: [
          { title: 'Introduction', icon: 'icon-68' },
          { title: 'Course Overview', icon: 'icon-68' },
          { title: 'Course Overview', badge_list: true, question: 0, minutes: 10 },
          { title: 'Course Exercise / Reference Files', icon: 'icon-68' },
          { title: 'Code Editor Installation (Optional if you have one)', icon: 'icon-68' },
          { title: ' Embedding PHP in HTML', icon: 'icon-68' },
        ]
      },
      {
        title: 'Week 5-8',
        text: 'Advanced story telling techniques for writers: Personas, Characters & Plots',
        lessons: [
          { title: 'Defining Functions', icon: 'icon-68' },
          { title: 'Function Parameters', icon: 'icon-68' },
          { title: ' Return Values From Functions', badge_list: true, question: 2, minutes: 15 },
          { title: ' Global Variable and Scope', icon: 'icon-68' },
          { title: 'Newer Way of creating a Constant', icon: 'icon-68' },
          { title: 'Constants', icon: 'icon-68' },
        ]
      },
    ],
    reviews:[
      {img:'/assets/images/blog/comment-01.jpg',rating:5,name:'Haley Bennet',date:'Oct 10, 2021',desc:'Lorem ipsum dolor sit amet, consectetur adipisicing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'},
      {img:'/assets/images/blog/comment-02.jpg',rating:5,name:'Simon Baker',date:'Oct 15, 2021',desc:'Lorem ipsum dolor sit amet, consectetur adipisicing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'},
      {img:'/assets/images/blog/comment-03.jpg',rating:5,name:'Richard Gere',date:'Oct 19, 2021',desc:'Lorem ipsum dolor sit amet, consectetur adipisicing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'},
    ]
  },
  {
    id: 2,
    img: 'course-02.jpg',
    duration: '3 Weeks',
    course_outline:'Online Only',
    level: 'Beginner',
    title: 'Java Programming Masterclass for Software Developers',
    rating: '4.4',
    rating_count: 10,
    course_price: '78.99',
    lesson: 15,
    student: 35,
    category:'Development',
    short_desc: 'Lorem ipsum dolor sit amet consectur adipiscing elit sed eiusmod tempor.',
    instructor: 'Susan White',
    instructor_img:'team-05.webp',
    instructor_title:'Founder & CEO',
    instructor_desc:'Consectetur adipisicing elit, sed do eiusmod tempor incididunt labore et dolore magna aliqua enim minim veniam quis nostrud exercitation ulla mco laboris nisi ut aliquip ex ea commodo consequat. duis aute irure dolor in reprehenderit in voluptate.',
    features: [
      'Build fully-fledged websites and web apps for your business.',
      'After the course you will be able to build ANY website you want.',
      'You will master both front and back-end development technologies.'
    ], 
    social_links:[
      {link: 'http://facebook.com', target: '_blank', icon: 'icon-facebook'},
      {link: 'http://twitter.com', target: '_blank', icon: 'icon-twitter'},
      {link: 'https://www.linkedin.com/', target: '_blank', icon: 'icon-linkedin2'},
      {link: 'https://www.youtube.com/', target: '_blank', icon: 'icon-youtube'}
    ],
    language: 'Korean',
    certificate: 'yes',
    videoId: 'PICj5tr9hcc',
    course_desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor inc idid unt ut labore et dolore magna aliqua enim ad minim veniam, quis nostrud exerec tation ullamco laboris nis aliquip commodo consequat duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur enim ipsam.',
    course_desc_2: 'Excepteur sint occaecat cupidatat non proident sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium totam rem aperiam.',
    learn_list: ['Learn to use Python professionally, learning both Python 2 & Python 3!', 'Build 6 beautiful real-world projects for your portfolio (not boring toy apps)', 'Understand the Theory behind Vue.js and use it in Real Projects', 'Create responsive, accessible, and beautiful layouts'],
    course_desc_3: 'Consectetur adipisicing elit, sed do eiusmod tempor inc idid unt ut labore et dolore magna aliqua enim ad minim veniam quis nostrud exerec tation ullamco laboris nis aliquip commodo consequat duis aute irure dolor.',
    curriculum_desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor inc idid unt ut labore et dolore magna aliqua enim ad minim veniam, quis nostrud exerec tation ullamco laboris nis aliquip commodo consequat.',
    course_lessons: [
      {
        title: 'Week 1-4',
        text: 'Advanced story telling techniques for writers: Personas, Characters & Plots',
        lessons: [
          { title: 'Introduction', icon: 'icon-68' },
          { title: 'Course Overview', icon: 'icon-68' },
          { title: 'Course Overview', badge_list: true, question: 0, minutes: 10 },
          { title: 'Course Exercise / Reference Files', icon: 'icon-68' },
          { title: 'Code Editor Installation (Optional if you have one)', icon: 'icon-68' },
          { title: ' Embedding PHP in HTML', icon: 'icon-68' },
        ]
      },
      {
        title: 'Week 5-8',
        text: 'Advanced story telling techniques for writers: Personas, Characters & Plots',
        lessons: [
          { title: 'Defining Functions', icon: 'icon-68' },
          { title: 'Function Parameters', icon: 'icon-68' },
          { title: ' Return Values From Functions', badge_list: true, question: 2, minutes: 15 },
          { title: ' Global Variable and Scope', icon: 'icon-68' },
          { title: 'Newer Way of creating a Constant', icon: 'icon-68' },
          { title: 'Constants', icon: 'icon-68' },
        ]
      },
    ],
    reviews:[
      {img:'/assets/images/blog/comment-01.jpg',rating:5,name:'Haley Bennet',date:'Oct 10, 2021',desc:'Lorem ipsum dolor sit amet, consectetur adipisicing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'},
      {img:'/assets/images/blog/comment-02.jpg',rating:5,name:'Simon Baker',date:'Oct 15, 2021',desc:'Lorem ipsum dolor sit amet, consectetur adipisicing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'},
      {img:'/assets/images/blog/comment-03.jpg',rating:5,name:'Richard Gere',date:'Oct 19, 2021',desc:'Lorem ipsum dolor sit amet, consectetur adipisicing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'},
    ]
  },
  {
    id: 3,
    img: 'course-03.jpg',
    duration: '10 Weeks',
    course_outline:'Onsite Only',
    level: 'Advanced',
    title: 'Building A Better World One Student At A Time',
    rating: '5.0',
    rating_count: 15,
    course_price: '64.99',
    lesson: 18,
    student: 33,
    category:'Business',
    short_desc: 'Lorem ipsum dolor sit amet consectur adipiscing elit sed eiusmod tempor.',
    instructor: 'Penelope Cruz',
    instructor_img:'team-02.webp',
    instructor_title:'Founder & CEO',
    instructor_desc:'Consectetur adipisicing elit, sed do eiusmod tempor incididunt labore et dolore magna aliqua enim minim veniam quis nostrud exercitation ulla mco laboris nisi ut aliquip ex ea commodo consequat. duis aute irure dolor in reprehenderit in voluptate.',
    features: [
      'Build fully-fledged websites and web apps for your business.',
      'After the course you will be able to build ANY website you want.',
      'You will master both front and back-end development technologies.'
    ], 
    social_links:[
      {link: 'http://facebook.com', target: '_blank', icon: 'icon-facebook'},
      {link: 'http://twitter.com', target: '_blank', icon: 'icon-twitter'},
      {link: 'https://www.linkedin.com/', target: '_blank', icon: 'icon-linkedin2'},
      {link: 'https://www.youtube.com/', target: '_blank', icon: 'icon-youtube'}
    ],
    language: 'English',
    certificate: 'yes',
    videoId: 'PICj5tr9hcc',
    course_desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor inc idid unt ut labore et dolore magna aliqua enim ad minim veniam, quis nostrud exerec tation ullamco laboris nis aliquip commodo consequat duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur enim ipsam.',
    course_desc_2: 'Excepteur sint occaecat cupidatat non proident sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium totam rem aperiam.',
    learn_list: ['Learn to use Python professionally, learning both Python 2 & Python 3!', 'Build 6 beautiful real-world projects for your portfolio (not boring toy apps)', 'Understand the Theory behind Vue.js and use it in Real Projects', 'Create responsive, accessible, and beautiful layouts'],
    course_desc_3: 'Consectetur adipisicing elit, sed do eiusmod tempor inc idid unt ut labore et dolore magna aliqua enim ad minim veniam quis nostrud exerec tation ullamco laboris nis aliquip commodo consequat duis aute irure dolor.',
    curriculum_desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor inc idid unt ut labore et dolore magna aliqua enim ad minim veniam, quis nostrud exerec tation ullamco laboris nis aliquip commodo consequat.',
    course_lessons: [
      {
        title: 'Week 1-4',
        text: 'Advanced story telling techniques for writers: Personas, Characters & Plots',
        lessons: [
          { title: 'Introduction', icon: 'icon-68' },
          { title: 'Course Overview', icon: 'icon-68' },
          { title: 'Course Overview', badge_list: true, question: 0, minutes: 10 },
          { title: 'Course Exercise / Reference Files', icon: 'icon-68' },
          { title: 'Code Editor Installation (Optional if you have one)', icon: 'icon-68' },
          { title: ' Embedding PHP in HTML', icon: 'icon-68' },
        ]
      },
      {
        title: 'Week 5-8',
        text: 'Advanced story telling techniques for writers: Personas, Characters & Plots',
        lessons: [
          { title: 'Defining Functions', icon: 'icon-68' },
          { title: 'Function Parameters', icon: 'icon-68' },
          { title: ' Return Values From Functions', badge_list: true, question: 2, minutes: 15 },
          { title: ' Global Variable and Scope', icon: 'icon-68' },
          { title: 'Newer Way of creating a Constant', icon: 'icon-68' },
          { title: 'Constants', icon: 'icon-68' },
        ]
      },
    ],
    reviews:[
      {img:'/assets/images/blog/comment-01.jpg',rating:5,name:'Haley Bennet',date:'Oct 10, 2021',desc:'Lorem ipsum dolor sit amet, consectetur adipisicing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'},
      {img:'/assets/images/blog/comment-02.jpg',rating:5,name:'Simon Baker',date:'Oct 15, 2021',desc:'Lorem ipsum dolor sit amet, consectetur adipisicing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'},
      {img:'/assets/images/blog/comment-03.jpg',rating:5,name:'Richard Gere',date:'Oct 19, 2021',desc:'Lorem ipsum dolor sit amet, consectetur adipisicing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'},
    ]
  },
  {
    id: 4,
    img: 'course-04.jpg',
    duration: '6 Weeks',
    course_outline:'Online Only',
    level: 'Intermediate',
    title: 'Master Your Personal Brand Like a Marketing Pro',
    rating: '4.5',
    rating_count: 18,
    course_price: '55.99',
    lesson: 20,
    student: 38,
    category:'Academics',
    short_desc: 'Lorem ipsum dolor sit amet consectur adipiscing elit sed eiusmod tempor.',
    instructor: 'John Travolta',
    instructor_img:'team-02.webp',
    instructor_title:'Founder & CEO',
    instructor_desc:'Consectetur adipisicing elit, sed do eiusmod tempor incididunt labore et dolore magna aliqua enim minim veniam quis nostrud exercitation ulla mco laboris nisi ut aliquip ex ea commodo consequat. duis aute irure dolor in reprehenderit in voluptate.',
    features: [
      'Build fully-fledged websites and web apps for your business.',
      'After the course you will be able to build ANY website you want.',
      'You will master both front and back-end development technologies.'
    ], 
    social_links:[
      {link: 'http://facebook.com', target: '_blank', icon: 'icon-facebook'},
      {link: 'http://twitter.com', target: '_blank', icon: 'icon-twitter'},
      {link: 'https://www.linkedin.com/', target: '_blank', icon: 'icon-linkedin2'},
      {link: 'https://www.youtube.com/', target: '_blank', icon: 'icon-youtube'}
    ],
    language: 'German',
    certificate: 'yes',
    videoId: 'PICj5tr9hcc',
    course_desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor inc idid unt ut labore et dolore magna aliqua enim ad minim veniam, quis nostrud exerec tation ullamco laboris nis aliquip commodo consequat duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur enim ipsam.',
    course_desc_2: 'Excepteur sint occaecat cupidatat non proident sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium totam rem aperiam.',
    learn_list: ['Learn to use Python professionally, learning both Python 2 & Python 3!', 'Build 6 beautiful real-world projects for your portfolio (not boring toy apps)', 'Understand the Theory behind Vue.js and use it in Real Projects', 'Create responsive, accessible, and beautiful layouts'],
    course_desc_3: 'Consectetur adipisicing elit, sed do eiusmod tempor inc idid unt ut labore et dolore magna aliqua enim ad minim veniam quis nostrud exerec tation ullamco laboris nis aliquip commodo consequat duis aute irure dolor.',
    curriculum_desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor inc idid unt ut labore et dolore magna aliqua enim ad minim veniam, quis nostrud exerec tation ullamco laboris nis aliquip commodo consequat.',
    course_lessons: [
      {
        title: 'Week 1-4',
        text: 'Advanced story telling techniques for writers: Personas, Characters & Plots',
        lessons: [
          { title: 'Introduction', icon: 'icon-68' },
          { title: 'Course Overview', icon: 'icon-68' },
          { title: 'Course Overview', badge_list: true, question: 0, minutes: 10 },
          { title: 'Course Exercise / Reference Files', icon: 'icon-68' },
          { title: 'Code Editor Installation (Optional if you have one)', icon: 'icon-68' },
          { title: ' Embedding PHP in HTML', icon: 'icon-68' },
        ]
      },
      {
        title: 'Week 5-8',
        text: 'Advanced story telling techniques for writers: Personas, Characters & Plots',
        lessons: [
          { title: 'Defining Functions', icon: 'icon-68' },
          { title: 'Function Parameters', icon: 'icon-68' },
          { title: ' Return Values From Functions', badge_list: true, question: 2, minutes: 15 },
          { title: ' Global Variable and Scope', icon: 'icon-68' },
          { title: 'Newer Way of creating a Constant', icon: 'icon-68' },
          { title: 'Constants', icon: 'icon-68' },
        ]
      },
    ],
    reviews:[
      {img:'/assets/images/blog/comment-01.jpg',rating:5,name:'Haley Bennet',date:'Oct 10, 2021',desc:'Lorem ipsum dolor sit amet, consectetur adipisicing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'},
      {img:'/assets/images/blog/comment-02.jpg',rating:5,name:'Simon Baker',date:'Oct 15, 2021',desc:'Lorem ipsum dolor sit amet, consectetur adipisicing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'},
      {img:'/assets/images/blog/comment-03.jpg',rating:5,name:'Richard Gere',date:'Oct 19, 2021',desc:'Lorem ipsum dolor sit amet, consectetur adipisicing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'},
    ]
  },
  {
    id: 5,
    img: 'course-05.jpg',
    duration: '6 Weeks',
    course_outline:'Online + Onsite',
    level: 'Advanced',
    title: 'The Complete 2023 Web Development Bootcamp',
    rating: '4.8',
    rating_count: 27,
    course_price: '49.99',
    lesson: 35,
    student: 48,
    category:'Art & Design',
    short_desc: 'Lorem ipsum dolor sit amet consectur adipiscing elit sed eiusmod tempor.',
    instructor: 'Edward Norton',
    instructor_img:'team-02.webp',
    instructor_title:'Founder & CEO',
    instructor_desc:'Consectetur adipisicing elit, sed do eiusmod tempor incididunt labore et dolore magna aliqua enim minim veniam quis nostrud exercitation ulla mco laboris nisi ut aliquip ex ea commodo consequat. duis aute irure dolor in reprehenderit in voluptate.',
    features: [
      'Build fully-fledged websites and web apps for your business.',
      'After the course you will be able to build ANY website you want.',
      'You will master both front and back-end development technologies.'
    ], 
    social_links:[
      {link: 'http://facebook.com', target: '_blank', icon: 'icon-facebook'},
      {link: 'http://twitter.com', target: '_blank', icon: 'icon-twitter'},
      {link: 'https://www.linkedin.com/', target: '_blank', icon: 'icon-linkedin2'},
      {link: 'https://www.youtube.com/', target: '_blank', icon: 'icon-youtube'}
    ],
    language: 'English',
    certificate: 'yes',
    videoId: 'PICj5tr9hcc',
    course_desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor inc idid unt ut labore et dolore magna aliqua enim ad minim veniam, quis nostrud exerec tation ullamco laboris nis aliquip commodo consequat duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur enim ipsam.',
    course_desc_2: 'Excepteur sint occaecat cupidatat non proident sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium totam rem aperiam.',
    learn_list: ['Learn to use Python professionally, learning both Python 2 & Python 3!', 'Build 6 beautiful real-world projects for your portfolio (not boring toy apps)', 'Understand the Theory behind Vue.js and use it in Real Projects', 'Create responsive, accessible, and beautiful layouts'],
    course_desc_3: 'Consectetur adipisicing elit, sed do eiusmod tempor inc idid unt ut labore et dolore magna aliqua enim ad minim veniam quis nostrud exerec tation ullamco laboris nis aliquip commodo consequat duis aute irure dolor.',
    curriculum_desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor inc idid unt ut labore et dolore magna aliqua enim ad minim veniam, quis nostrud exerec tation ullamco laboris nis aliquip commodo consequat.',
    course_lessons: [
      {
        title: 'Week 1-4',
        text: 'Advanced story telling techniques for writers: Personas, Characters & Plots',
        lessons: [
          { title: 'Introduction', icon: 'icon-68' },
          { title: 'Course Overview', icon: 'icon-68' },
          { title: 'Course Overview', badge_list: true, question: 0, minutes: 10 },
          { title: 'Course Exercise / Reference Files', icon: 'icon-68' },
          { title: 'Code Editor Installation (Optional if you have one)', icon: 'icon-68' },
          { title: ' Embedding PHP in HTML', icon: 'icon-68' },
        ]
      },
      {
        title: 'Week 5-8',
        text: 'Advanced story telling techniques for writers: Personas, Characters & Plots',
        lessons: [
          { title: 'Defining Functions', icon: 'icon-68' },
          { title: 'Function Parameters', icon: 'icon-68' },
          { title: ' Return Values From Functions', badge_list: true, question: 2, minutes: 15 },
          { title: ' Global Variable and Scope', icon: 'icon-68' },
          { title: 'Newer Way of creating a Constant', icon: 'icon-68' },
          { title: 'Constants', icon: 'icon-68' },
        ]
      },
    ],
    reviews:[
      {img:'/assets/images/blog/comment-01.jpg',rating:5,name:'Haley Bennet',date:'Oct 10, 2021',desc:'Lorem ipsum dolor sit amet, consectetur adipisicing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'},
      {img:'/assets/images/blog/comment-02.jpg',rating:5,name:'Simon Baker',date:'Oct 15, 2021',desc:'Lorem ipsum dolor sit amet, consectetur adipisicing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'},
      {img:'/assets/images/blog/comment-03.jpg',rating:5,name:'Richard Gere',date:'Oct 19, 2021',desc:'Lorem ipsum dolor sit amet, consectetur adipisicing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'},
    ]
  },
  {
    id: 6,
    img: 'course-06.jpg',
    duration: '9 Weeks',
    course_outline:'Online + Onsite',
    level: 'Intermediate',
    title: 'Python for Beginners - Learn Programming from scratch',
    rating: '4.9',
    rating_count: 30,
    course_price: '64.99',
    lesson: 12,
    student: 85,
    category:'Marketing',
    short_desc: 'Lorem ipsum dolor sit amet consectur adipiscing elit sed eiusmod tempor.',
    instructor: 'Penelope Cruz',
    instructor_img:'team-05.webp',
    instructor_title:'Founder & CEO',
    instructor_desc:'Consectetur adipisicing elit, sed do eiusmod tempor incididunt labore et dolore magna aliqua enim minim veniam quis nostrud exercitation ulla mco laboris nisi ut aliquip ex ea commodo consequat. duis aute irure dolor in reprehenderit in voluptate.',
    features: [
      'Build fully-fledged websites and web apps for your business.',
      'After the course you will be able to build ANY website you want.',
      'You will master both front and back-end development technologies.'
    ], 
    social_links:[
      {link: 'http://facebook.com', target: '_blank', icon: 'icon-facebook'},
      {link: 'http://twitter.com', target: '_blank', icon: 'icon-twitter'},
      {link: 'https://www.linkedin.com/', target: '_blank', icon: 'icon-linkedin2'},
      {link: 'https://www.youtube.com/', target: '_blank', icon: 'icon-youtube'}
    ],
    language: 'English',
    certificate: 'yes',
    videoId: 'PICj5tr9hcc',
    course_desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor inc idid unt ut labore et dolore magna aliqua enim ad minim veniam, quis nostrud exerec tation ullamco laboris nis aliquip commodo consequat duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur enim ipsam.',
    course_desc_2: 'Excepteur sint occaecat cupidatat non proident sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium totam rem aperiam.',
    learn_list: ['Learn to use Python professionally, learning both Python 2 & Python 3!', 'Build 6 beautiful real-world projects for your portfolio (not boring toy apps)', 'Understand the Theory behind Vue.js and use it in Real Projects', 'Create responsive, accessible, and beautiful layouts'],
    course_desc_3: 'Consectetur adipisicing elit, sed do eiusmod tempor inc idid unt ut labore et dolore magna aliqua enim ad minim veniam quis nostrud exerec tation ullamco laboris nis aliquip commodo consequat duis aute irure dolor.',
    curriculum_desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor inc idid unt ut labore et dolore magna aliqua enim ad minim veniam, quis nostrud exerec tation ullamco laboris nis aliquip commodo consequat.',
    course_lessons: [
      {
        title: 'Week 1-4',
        text: 'Advanced story telling techniques for writers: Personas, Characters & Plots',
        lessons: [
          { title: 'Introduction', icon: 'icon-68' },
          { title: 'Course Overview', icon: 'icon-68' },
          { title: 'Course Overview', badge_list: true, question: 0, minutes: 10 },
          { title: 'Course Exercise / Reference Files', icon: 'icon-68' },
          { title: 'Code Editor Installation (Optional if you have one)', icon: 'icon-68' },
          { title: ' Embedding PHP in HTML', icon: 'icon-68' },
        ]
      },
      {
        title: 'Week 5-8',
        text: 'Advanced story telling techniques for writers: Personas, Characters & Plots',
        lessons: [
          { title: 'Defining Functions', icon: 'icon-68' },
          { title: 'Function Parameters', icon: 'icon-68' },
          { title: ' Return Values From Functions', badge_list: true, question: 2, minutes: 15 },
          { title: ' Global Variable and Scope', icon: 'icon-68' },
          { title: 'Newer Way of creating a Constant', icon: 'icon-68' },
          { title: 'Constants', icon: 'icon-68' },
        ]
      },
    ],
    reviews:[
      {img:'/assets/images/blog/comment-01.jpg',rating:5,name:'Haley Bennet',date:'Oct 10, 2021',desc:'Lorem ipsum dolor sit amet, consectetur adipisicing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'},
      {img:'/assets/images/blog/comment-02.jpg',rating:5,name:'Simon Baker',date:'Oct 15, 2021',desc:'Lorem ipsum dolor sit amet, consectetur adipisicing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'},
      {img:'/assets/images/blog/comment-03.jpg',rating:5,name:'Richard Gere',date:'Oct 19, 2021',desc:'Lorem ipsum dolor sit amet, consectetur adipisicing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'},
    ]
  },
  {
    id: 7,
    university_courses:true,
    img: 'course-07.jpg',
    filter_category:["undergraduate","graduate"],
    duration: '6 Weeks',
    course_outline:'Online + Onsite',
    level: 'Business',
    title: 'Public Administration',
    rating: '5.0',
    rating_count: 11,
    course_price: '69.99',
    lesson: 20,
    student: 38,
    category:'Art & Design',
    short_desc: 'Lorem ipsum dolor sit amet consectur elit sed eiusmod ex tempor incididunt labore dolore magna.',
    instructor: 'Jane Seymour',
    instructor_img:'team-05.webp',
    instructor_title:'Founder & CEO',
    instructor_desc:'Consectetur adipisicing elit, sed do eiusmod tempor incididunt labore et dolore magna aliqua enim minim veniam quis nostrud exercitation ulla mco laboris nisi ut aliquip ex ea commodo consequat. duis aute irure dolor in reprehenderit in voluptate.',
    features: [
      'Build fully-fledged websites and web apps for your business.',
      'After the course you will be able to build ANY website you want.',
      'You will master both front and back-end development technologies.'
    ], 
    social_links:[
      {link: 'http://facebook.com', target: '_blank', icon: 'icon-facebook'},
      {link: 'http://twitter.com', target: '_blank', icon: 'icon-twitter'},
      {link: 'https://www.linkedin.com/', target: '_blank', icon: 'icon-linkedin2'},
      {link: 'https://www.youtube.com/', target: '_blank', icon: 'icon-youtube'}
    ],
    language:'Hindi',
    certificate:'yes',
    videoId:'c238xxeDdFI',
    course_desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor inc idid unt ut labore et dolore magna aliqua enim ad minim veniam, quis nostrud exerec tation ullamco laboris nis aliquip commodo consequat duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur enim ipsam.',
    course_desc_2: 'Excepteur sint occaecat cupidatat non proident sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium totam rem aperiam.',
    learn_list: ['Learn to use Python professionally, learning both Python 2 & Python 3!', 'Build 6 beautiful real-world projects for your portfolio (not boring toy apps)', 'Understand the Theory behind Vue.js and use it in Real Projects', 'Create responsive, accessible, and beautiful layouts'],
    course_desc_3: 'Consectetur adipisicing elit, sed do eiusmod tempor inc idid unt ut labore et dolore magna aliqua enim ad minim veniam quis nostrud exerec tation ullamco laboris nis aliquip commodo consequat duis aute irure dolor.',
    curriculum_desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor inc idid unt ut labore et dolore magna aliqua enim ad minim veniam, quis nostrud exerec tation ullamco laboris nis aliquip commodo consequat.',
    course_lessons: [
      {
        title: 'Week 1-4',
        text: 'Advanced story telling techniques for writers: Personas, Characters & Plots',
        lessons: [
          { title: 'Introduction', icon: 'icon-68' },
          { title: 'Course Overview', icon: 'icon-68' },
          { title: 'Course Overview', badge_list: true, question: 0, minutes: 10 },
          { title: 'Course Exercise / Reference Files', icon: 'icon-68' },
          { title: 'Code Editor Installation (Optional if you have one)', icon: 'icon-68' },
          { title: ' Embedding PHP in HTML', icon: 'icon-68' },
        ]
      },
      {
        title: 'Week 5-8',
        text: 'Advanced story telling techniques for writers: Personas, Characters & Plots',
        lessons: [
          { title: 'Defining Functions', icon: 'icon-68' },
          { title: 'Function Parameters', icon: 'icon-68' },
          { title: ' Return Values From Functions', badge_list: true, question: 2, minutes: 15 },
          { title: ' Global Variable and Scope', icon: 'icon-68' },
          { title: 'Newer Way of creating a Constant', icon: 'icon-68' },
          { title: 'Constants', icon: 'icon-68' },
        ]
      },
    ],
    reviews:[
      {img:'/assets/images/blog/comment-01.jpg',rating:5,name:'Haley Bennet',date:'Oct 10, 2021',desc:'Lorem ipsum dolor sit amet, consectetur adipisicing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'},
      {img:'/assets/images/blog/comment-02.jpg',rating:5,name:'Simon Baker',date:'Oct 15, 2021',desc:'Lorem ipsum dolor sit amet, consectetur adipisicing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'},
      {img:'/assets/images/blog/comment-03.jpg',rating:5,name:'Richard Gere',date:'Oct 19, 2021',desc:'Lorem ipsum dolor sit amet, consectetur adipisicing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'},
    ]
  },
  {
    id: 8,
    university_courses:true,
    img: 'course-08.jpg',
    filter_category:["undergraduate","graduate"],
    duration: '10 Weeks',
    course_outline:'Online + Onsite',
    level: 'Business',
    title: 'Major in Economics',
    rating: '4.9',
    rating_count: 15,
    course_price: '72.99',
    lesson: 20,
    student: 35,
    category:'Development',
    short_desc: 'Lorem ipsum dolor sit amet consectur elit sed eiusmod ex tempor incididunt labore dolore magna.',
    instructor: 'Susan White',
    instructor_img:'team-05.webp',
    instructor_title:'Founder & CEO',
    instructor_desc:'Consectetur adipisicing elit, sed do eiusmod tempor incididunt labore et dolore magna aliqua enim minim veniam quis nostrud exercitation ulla mco laboris nisi ut aliquip ex ea commodo consequat. duis aute irure dolor in reprehenderit in voluptate.',
    features: [
      'Build fully-fledged websites and web apps for your business.',
      'After the course you will be able to build ANY website you want.',
      'You will master both front and back-end development technologies.'
    ], 
    social_links:[
      {link: 'http://facebook.com', target: '_blank', icon: 'icon-facebook'},
      {link: 'http://twitter.com', target: '_blank', icon: 'icon-twitter'},
      {link: 'https://www.linkedin.com/', target: '_blank', icon: 'icon-linkedin2'},
      {link: 'https://www.youtube.com/', target: '_blank', icon: 'icon-youtube'}
    ],
    language: 'English',
    certificate: 'yes',
    videoId: 'PICj5tr9hcc',
    course_desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor inc idid unt ut labore et dolore magna aliqua enim ad minim veniam, quis nostrud exerec tation ullamco laboris nis aliquip commodo consequat duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur enim ipsam.',
    course_desc_2: 'Excepteur sint occaecat cupidatat non proident sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium totam rem aperiam.',
    learn_list: ['Learn to use Python professionally, learning both Python 2 & Python 3!', 'Build 6 beautiful real-world projects for your portfolio (not boring toy apps)', 'Understand the Theory behind Vue.js and use it in Real Projects', 'Create responsive, accessible, and beautiful layouts'],
    course_desc_3: 'Consectetur adipisicing elit, sed do eiusmod tempor inc idid unt ut labore et dolore magna aliqua enim ad minim veniam quis nostrud exerec tation ullamco laboris nis aliquip commodo consequat duis aute irure dolor.',
    curriculum_desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor inc idid unt ut labore et dolore magna aliqua enim ad minim veniam, quis nostrud exerec tation ullamco laboris nis aliquip commodo consequat.',
    course_lessons: [
      {
        title: 'Week 1-4',
        text: 'Advanced story telling techniques for writers: Personas, Characters & Plots',
        lessons: [
          { title: 'Introduction', icon: 'icon-68' },
          { title: 'Course Overview', icon: 'icon-68' },
          { title: 'Course Overview', badge_list: true, question: 0, minutes: 10 },
          { title: 'Course Exercise / Reference Files', icon: 'icon-68' },
          { title: 'Code Editor Installation (Optional if you have one)', icon: 'icon-68' },
          { title: ' Embedding PHP in HTML', icon: 'icon-68' },
        ]
      },
      {
        title: 'Week 5-8',
        text: 'Advanced story telling techniques for writers: Personas, Characters & Plots',
        lessons: [
          { title: 'Defining Functions', icon: 'icon-68' },
          { title: 'Function Parameters', icon: 'icon-68' },
          { title: ' Return Values From Functions', badge_list: true, question: 2, minutes: 15 },
          { title: ' Global Variable and Scope', icon: 'icon-68' },
          { title: 'Newer Way of creating a Constant', icon: 'icon-68' },
          { title: 'Constants', icon: 'icon-68' },
        ]
      },
    ],
    reviews:[
      {img:'/assets/images/blog/comment-01.jpg',rating:5,name:'Haley Bennet',date:'Oct 10, 2021',desc:'Lorem ipsum dolor sit amet, consectetur adipisicing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'},
      {img:'/assets/images/blog/comment-02.jpg',rating:5,name:'Simon Baker',date:'Oct 15, 2021',desc:'Lorem ipsum dolor sit amet, consectetur adipisicing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'},
      {img:'/assets/images/blog/comment-03.jpg',rating:5,name:'Richard Gere',date:'Oct 19, 2021',desc:'Lorem ipsum dolor sit amet, consectetur adipisicing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'},
    ]
  },
  {
    id: 9,
    university_courses:true,
    img: 'course-09.jpg',
    filter_category:["undergraduate","online"],
    duration: '3 Weeks',
    level: 'Business',
    course_outline:'Online Only',
    title: 'Business Studies',
    rating: '4.4',
    rating_count: 10,
    course_price: '78.99',
    lesson: 15,
    student: 35,
    category:'Art & Design',
    short_desc: 'Lorem ipsum dolor sit amet consectur elit sed eiusmod ex tempor incididunt labore dolore magna.',
    instructor: 'Penelope Cruz',
    instructor_img:'team-02.webp',
    instructor_title:'Founder & CEO',
    instructor_desc:'Consectetur adipisicing elit, sed do eiusmod tempor incididunt labore et dolore magna aliqua enim minim veniam quis nostrud exercitation ulla mco laboris nisi ut aliquip ex ea commodo consequat. duis aute irure dolor in reprehenderit in voluptate.',
    features: [
      'Build fully-fledged websites and web apps for your business.',
      'After the course you will be able to build ANY website you want.',
      'You will master both front and back-end development technologies.'
    ], 
    social_links:[
      {link: 'http://facebook.com', target: '_blank', icon: 'icon-facebook'},
      {link: 'http://twitter.com', target: '_blank', icon: 'icon-twitter'},
      {link: 'https://www.linkedin.com/', target: '_blank', icon: 'icon-linkedin2'},
      {link: 'https://www.youtube.com/', target: '_blank', icon: 'icon-youtube'}
    ],
    language: 'Korean',
    certificate: 'yes',
    videoId: 'PICj5tr9hcc',
    course_desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor inc idid unt ut labore et dolore magna aliqua enim ad minim veniam, quis nostrud exerec tation ullamco laboris nis aliquip commodo consequat duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur enim ipsam.',
    course_desc_2: 'Excepteur sint occaecat cupidatat non proident sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium totam rem aperiam.',
    learn_list: ['Learn to use Python professionally, learning both Python 2 & Python 3!', 'Build 6 beautiful real-world projects for your portfolio (not boring toy apps)', 'Understand the Theory behind Vue.js and use it in Real Projects', 'Create responsive, accessible, and beautiful layouts'],
    course_desc_3: 'Consectetur adipisicing elit, sed do eiusmod tempor inc idid unt ut labore et dolore magna aliqua enim ad minim veniam quis nostrud exerec tation ullamco laboris nis aliquip commodo consequat duis aute irure dolor.',
    curriculum_desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor inc idid unt ut labore et dolore magna aliqua enim ad minim veniam, quis nostrud exerec tation ullamco laboris nis aliquip commodo consequat.',
    course_lessons: [
      {
        title: 'Week 1-4',
        text: 'Advanced story telling techniques for writers: Personas, Characters & Plots',
        lessons: [
          { title: 'Introduction', icon: 'icon-68' },
          { title: 'Course Overview', icon: 'icon-68' },
          { title: 'Course Overview', badge_list: true, question: 0, minutes: 10 },
          { title: 'Course Exercise / Reference Files', icon: 'icon-68' },
          { title: 'Code Editor Installation (Optional if you have one)', icon: 'icon-68' },
          { title: ' Embedding PHP in HTML', icon: 'icon-68' },
        ]
      },
      {
        title: 'Week 5-8',
        text: 'Advanced story telling techniques for writers: Personas, Characters & Plots',
        lessons: [
          { title: 'Defining Functions', icon: 'icon-68' },
          { title: 'Function Parameters', icon: 'icon-68' },
          { title: ' Return Values From Functions', badge_list: true, question: 2, minutes: 15 },
          { title: ' Global Variable and Scope', icon: 'icon-68' },
          { title: 'Newer Way of creating a Constant', icon: 'icon-68' },
          { title: 'Constants', icon: 'icon-68' },
        ]
      },
    ],
    reviews:[
      {img:'/assets/images/blog/comment-01.jpg',rating:5,name:'Haley Bennet',date:'Oct 10, 2021',desc:'Lorem ipsum dolor sit amet, consectetur adipisicing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'},
      {img:'/assets/images/blog/comment-02.jpg',rating:5,name:'Simon Baker',date:'Oct 15, 2021',desc:'Lorem ipsum dolor sit amet, consectetur adipisicing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'},
      {img:'/assets/images/blog/comment-03.jpg',rating:5,name:'Richard Gere',date:'Oct 19, 2021',desc:'Lorem ipsum dolor sit amet, consectetur adipisicing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'},
    ]
  },
  {
    id: 10,
    duration: '12 Weeks',
    course_outline:'Onsite Only',
    img: 'course-10.jpg',
    hours: 35,
    level: 'Beginner',
    title: 'Master Microservices with Spring Boot and Spring Cloud',
    rating: '5',
    rating_count: 8,
    course_price: '35',
    lesson: 13,
    student: 35,
    category:'Art & Design',
    short_desc: 'Lorem ipsum dolor sit amet consectur adipiscing elit sed eiusmod tempor.',
    instructor: 'Edward Norton',
    instructor_img:'team-02.webp',
    instructor_title:'Founder & CEO',
    instructor_desc:'Consectetur adipisicing elit, sed do eiusmod tempor incididunt labore et dolore magna aliqua enim minim veniam quis nostrud exercitation ulla mco laboris nisi ut aliquip ex ea commodo consequat. duis aute irure dolor in reprehenderit in voluptate.',
    features: [
      'Build fully-fledged websites and web apps for your business.',
      'After the course you will be able to build ANY website you want.',
      'You will master both front and back-end development technologies.'
    ], 
    social_links:[
      {link: 'http://facebook.com', target: '_blank', icon: 'icon-facebook'},
      {link: 'http://twitter.com', target: '_blank', icon: 'icon-twitter'},
      {link: 'https://www.linkedin.com/', target: '_blank', icon: 'icon-linkedin2'},
      {link: 'https://www.youtube.com/', target: '_blank', icon: 'icon-youtube'}
    ],
    language: 'English',
    certificate: 'yes',
    videoId: 'PICj5tr9hcc',
    course_desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor inc idid unt ut labore et dolore magna aliqua enim ad minim veniam, quis nostrud exerec tation ullamco laboris nis aliquip commodo consequat duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur enim ipsam.',
    course_desc_2: 'Excepteur sint occaecat cupidatat non proident sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium totam rem aperiam.',
    learn_list: ['Professional Japanese cooking from beginners to experts','Will be able to cook authentic Italian recipes in their own kitchen','Understand the HOW of cooking, before thinking of the WHAT to cook.'],
    course_desc_3: 'Consectetur adipisicing elit, sed do eiusmod tempor inc idid unt ut labore et dolore magna aliqua enim ad minim veniam quis nostrud exerec tation ullamco laboris nis aliquip commodo consequat duis aute irure dolor.',
    curriculum_desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor inc idid unt ut labore et dolore magna aliqua enim ad minim veniam, quis nostrud exerec tation ullamco laboris nis aliquip commodo consequat.',
    course_lessons: [
      {
        title: 'Week 1-4',
        text: 'Advanced story telling techniques for writers: Personas, Characters & Plots',
        lessons: [
          { title: 'Introduction', icon: 'icon-68' },
          { title: 'Course Overview', icon: 'icon-68' },
          { title: 'Course Overview', badge_list: true, question: 0, minutes: 10 },
          { title: 'Course Exercise / Reference Files', icon: 'icon-68' },
          { title: 'Code Editor Installation (Optional if you have one)', icon: 'icon-68' },
          { title: ' Embedding PHP in HTML', icon: 'icon-68' },
        ]
      },
      {
        title: 'Week 5-8',
        text: 'Advanced story telling techniques for writers: Personas, Characters & Plots',
        lessons: [
          { title: 'Defining Functions', icon: 'icon-68' },
          { title: 'Function Parameters', icon: 'icon-68' },
          { title: ' Return Values From Functions', badge_list: true, question: 2, minutes: 15 },
          { title: ' Global Variable and Scope', icon: 'icon-68' },
          { title: 'Newer Way of creating a Constant', icon: 'icon-68' },
          { title: 'Constants', icon: 'icon-68' },
        ]
      },
    ],
    reviews:[
      {img:'/assets/images/blog/comment-01.jpg',rating:5,name:'Haley Bennet',date:'Oct 10, 2021',desc:'Lorem ipsum dolor sit amet, consectetur adipisicing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'},
      {img:'/assets/images/blog/comment-02.jpg',rating:5,name:'Simon Baker',date:'Oct 15, 2021',desc:'Lorem ipsum dolor sit amet, consectetur adipisicing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'},
      {img:'/assets/images/blog/comment-03.jpg',rating:5,name:'Richard Gere',date:'Oct 19, 2021',desc:'Lorem ipsum dolor sit amet, consectetur adipisicing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'},
    ]
  },
  {
    id: 11,
    duration: '9 Weeks',
    course_outline:'Online Only',
    img: 'course-11.jpg',
    hours: 45,
    level: 'Intermediate',
    title: 'The Complete Angular Course: Beginner to Advanced',
    rating: '4.8',
    rating_count: 16,
    course_price: '40',
    lesson: 18,
    student: 42,
    category:'Art & Design',
    short_desc: 'Lorem ipsum dolor sit amet consectur adipiscing elit sed eiusmod tempor.',
    instructor: 'Connie Comerford',
    instructor_img:'team-06.webp',
    instructor_title:'Founder & CEO',
    instructor_desc:'Consectetur adipisicing elit, sed do eiusmod tempor incididunt labore et dolore magna aliqua enim minim veniam quis nostrud exercitation ulla mco laboris nisi ut aliquip ex ea commodo consequat. duis aute irure dolor in reprehenderit in voluptate.',
    features: [
      'Build fully-fledged websites and web apps for your business.',
      'After the course you will be able to build ANY website you want.',
      'You will master both front and back-end development technologies.'
    ], 
    social_links:[
      {link: 'http://facebook.com', target: '_blank', icon: 'icon-facebook'},
      {link: 'http://twitter.com', target: '_blank', icon: 'icon-twitter'},
      {link: 'https://www.linkedin.com/', target: '_blank', icon: 'icon-linkedin2'},
      {link: 'https://www.youtube.com/', target: '_blank', icon: 'icon-youtube'}
    ],
    language: 'German',
    certificate: 'yes',
    videoId: 'PICj5tr9hcc',
    course_desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor inc idid unt ut labore et dolore magna aliqua enim ad minim veniam, quis nostrud exerec tation ullamco laboris nis aliquip commodo consequat duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur enim ipsam.',
    course_desc_2: 'Excepteur sint occaecat cupidatat non proident sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium totam rem aperiam.',
    learn_list: ['Professional Japanese cooking from beginners to experts','Will be able to cook authentic Italian recipes in their own kitchen','Understand the HOW of cooking, before thinking of the WHAT to cook.'],
    course_desc_3: 'Consectetur adipisicing elit, sed do eiusmod tempor inc idid unt ut labore et dolore magna aliqua enim ad minim veniam quis nostrud exerec tation ullamco laboris nis aliquip commodo consequat duis aute irure dolor.',
    curriculum_desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor inc idid unt ut labore et dolore magna aliqua enim ad minim veniam, quis nostrud exerec tation ullamco laboris nis aliquip commodo consequat.',
    course_lessons: [
      {
        title: 'Week 1-4',
        text: 'Advanced story telling techniques for writers: Personas, Characters & Plots',
        lessons: [
          { title: 'Introduction', icon: 'icon-68' },
          { title: 'Course Overview', icon: 'icon-68' },
          { title: 'Course Overview', badge_list: true, question: 0, minutes: 10 },
          { title: 'Course Exercise / Reference Files', icon: 'icon-68' },
          { title: 'Code Editor Installation (Optional if you have one)', icon: 'icon-68' },
          { title: ' Embedding PHP in HTML', icon: 'icon-68' },
        ]
      },
      {
        title: 'Week 5-8',
        text: 'Advanced story telling techniques for writers: Personas, Characters & Plots',
        lessons: [
          { title: 'Defining Functions', icon: 'icon-68' },
          { title: 'Function Parameters', icon: 'icon-68' },
          { title: ' Return Values From Functions', badge_list: true, question: 2, minutes: 15 },
          { title: ' Global Variable and Scope', icon: 'icon-68' },
          { title: 'Newer Way of creating a Constant', icon: 'icon-68' },
          { title: 'Constants', icon: 'icon-68' },
        ]
      },
    ],
    reviews:[
      {img:'/assets/images/blog/comment-01.jpg',rating:5,name:'Haley Bennet',date:'Oct 10, 2021',desc:'Lorem ipsum dolor sit amet, consectetur adipisicing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'},
      {img:'/assets/images/blog/comment-02.jpg',rating:5,name:'Simon Baker',date:'Oct 15, 2021',desc:'Lorem ipsum dolor sit amet, consectetur adipisicing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'},
      {img:'/assets/images/blog/comment-03.jpg',rating:5,name:'Richard Gere',date:'Oct 19, 2021',desc:'Lorem ipsum dolor sit amet, consectetur adipisicing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'},
    ]
  },
  {
    id: 12,
    duration: '8 Weeks',
    course_outline:'Online + Onsite',
    img: 'course-12.jpg',
    hours: 20,
    level: 'Advanced',
    title: 'Vue - The Complete Guide (w/ Router, Vuex, Composition API)',
    rating: '5',
    rating_count: 20,
    course_price: '50',
    lesson: 8,
    student: 50,
    category:'Marketing',
    short_desc: 'Lorem ipsum dolor sit amet consectur adipiscing elit sed eiusmod tempor.',
    instructor: 'Edward Norton',
    instructor_img:'team-02.webp',
    instructor_title:'Founder & CEO',
    instructor_desc:'Consectetur adipisicing elit, sed do eiusmod tempor incididunt labore et dolore magna aliqua enim minim veniam quis nostrud exercitation ulla mco laboris nisi ut aliquip ex ea commodo consequat. duis aute irure dolor in reprehenderit in voluptate.',
    features: [
      'Build fully-fledged websites and web apps for your business.',
      'After the course you will be able to build ANY website you want.',
      'You will master both front and back-end development technologies.'
    ], 
    social_links:[
      {link: 'http://facebook.com', target: '_blank', icon: 'icon-facebook'},
      {link: 'http://twitter.com', target: '_blank', icon: 'icon-twitter'},
      {link: 'https://www.linkedin.com/', target: '_blank', icon: 'icon-linkedin2'},
      {link: 'https://www.youtube.com/', target: '_blank', icon: 'icon-youtube'}
    ],
    language: 'Hindi',
    certificate: 'yes',
    videoId: 'PICj5tr9hcc',
    course_desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor inc idid unt ut labore et dolore magna aliqua enim ad minim veniam, quis nostrud exerec tation ullamco laboris nis aliquip commodo consequat duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur enim ipsam.',
    course_desc_2: 'Excepteur sint occaecat cupidatat non proident sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium totam rem aperiam.',
    learn_list: ['Professional Japanese cooking from beginners to experts','Will be able to cook authentic Italian recipes in their own kitchen','Understand the HOW of cooking, before thinking of the WHAT to cook.'],
    course_desc_3: 'Consectetur adipisicing elit, sed do eiusmod tempor inc idid unt ut labore et dolore magna aliqua enim ad minim veniam quis nostrud exerec tation ullamco laboris nis aliquip commodo consequat duis aute irure dolor.',
    curriculum_desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor inc idid unt ut labore et dolore magna aliqua enim ad minim veniam, quis nostrud exerec tation ullamco laboris nis aliquip commodo consequat.',
    course_lessons: [
      {
        title: 'Week 1-4',
        text: 'Advanced story telling techniques for writers: Personas, Characters & Plots',
        lessons: [
          { title: 'Introduction', icon: 'icon-68' },
          { title: 'Course Overview', icon: 'icon-68' },
          { title: 'Course Overview', badge_list: true, question: 0, minutes: 10 },
          { title: 'Course Exercise / Reference Files', icon: 'icon-68' },
          { title: 'Code Editor Installation (Optional if you have one)', icon: 'icon-68' },
          { title: ' Embedding PHP in HTML', icon: 'icon-68' },
        ]
      },
      {
        title: 'Week 5-8',
        text: 'Advanced story telling techniques for writers: Personas, Characters & Plots',
        lessons: [
          { title: 'Defining Functions', icon: 'icon-68' },
          { title: 'Function Parameters', icon: 'icon-68' },
          { title: ' Return Values From Functions', badge_list: true, question: 2, minutes: 15 },
          { title: ' Global Variable and Scope', icon: 'icon-68' },
          { title: 'Newer Way of creating a Constant', icon: 'icon-68' },
          { title: 'Constants', icon: 'icon-68' },
        ]
      },
    ],
    reviews:[
      {img:'/assets/images/blog/comment-01.jpg',rating:5,name:'Haley Bennet',date:'Oct 10, 2021',desc:'Lorem ipsum dolor sit amet, consectetur adipisicing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'},
      {img:'/assets/images/blog/comment-02.jpg',rating:5,name:'Simon Baker',date:'Oct 15, 2021',desc:'Lorem ipsum dolor sit amet, consectetur adipisicing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'},
      {img:'/assets/images/blog/comment-03.jpg',rating:5,name:'Richard Gere',date:'Oct 19, 2021',desc:'Lorem ipsum dolor sit amet, consectetur adipisicing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'},
    ]
  },
  {
    id: 13,
    duration: '14 Weeks',
    course_outline:'Onsite Only',
    img: 'course-13.jpg',
    hours: 25,
    level: 'Power Yoga',
    title: 'Build Responsive Real- World Websites with HTML and CSS',
    rating: '4.5',
    rating_count: 20,
    course_price: '30',
    lesson: 18,
    student: 25,
    category:'Academics',
    short_desc: 'Lorem ipsum dolor sit amet consectur adipiscing elit sed eiusmod tempor.',
    instructor: 'Jane Seymour',
    instructor_img:'team-01.webp',
    instructor_title:'Founder & CEO',
    instructor_desc:'Consectetur adipisicing elit, sed do eiusmod tempor incididunt labore et dolore magna aliqua enim minim veniam quis nostrud exercitation ulla mco laboris nisi ut aliquip ex ea commodo consequat. duis aute irure dolor in reprehenderit in voluptate.',
    features: [
      'Build fully-fledged websites and web apps for your business.',
      'After the course you will be able to build ANY website you want.',
      'You will master both front and back-end development technologies.'
    ], 
    social_links:[
      {link: 'http://facebook.com', target: '_blank', icon: 'icon-facebook'},
      {link: 'http://twitter.com', target: '_blank', icon: 'icon-twitter'},
      {link: 'https://www.linkedin.com/', target: '_blank', icon: 'icon-linkedin2'},
      {link: 'https://www.youtube.com/', target: '_blank', icon: 'icon-youtube'}
    ],
    language: 'English',
    certificate: 'yes',
    videoId: 'PICj5tr9hcc',
    course_desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor inc idid unt ut labore et dolore magna aliqua enim ad minim veniam, quis nostrud exerec tation ullamco laboris nis aliquip commodo consequat duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur enim ipsam.',
    course_desc_2: 'Excepteur sint occaecat cupidatat non proident sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium totam rem aperiam.',
    learn_list: ['Professional Japanese cooking from beginners to experts','Will be able to cook authentic Italian recipes in their own kitchen','Understand the HOW of cooking, before thinking of the WHAT to cook.'],
    course_desc_3: 'Consectetur adipisicing elit, sed do eiusmod tempor inc idid unt ut labore et dolore magna aliqua enim ad minim veniam quis nostrud exerec tation ullamco laboris nis aliquip commodo consequat duis aute irure dolor.',
    curriculum_desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor inc idid unt ut labore et dolore magna aliqua enim ad minim veniam, quis nostrud exerec tation ullamco laboris nis aliquip commodo consequat.',
    course_lessons: [
      {
        title: 'Week 1-4',
        text: 'Advanced story telling techniques for writers: Personas, Characters & Plots',
        lessons: [
          { title: 'Introduction', icon: 'icon-68' },
          { title: 'Course Overview', icon: 'icon-68' },
          { title: 'Course Overview', badge_list: true, question: 0, minutes: 10 },
          { title: 'Course Exercise / Reference Files', icon: 'icon-68' },
          { title: 'Code Editor Installation (Optional if you have one)', icon: 'icon-68' },
          { title: ' Embedding PHP in HTML', icon: 'icon-68' },
        ]
      },
      {
        title: 'Week 5-8',
        text: 'Advanced story telling techniques for writers: Personas, Characters & Plots',
        lessons: [
          { title: 'Defining Functions', icon: 'icon-68' },
          { title: 'Function Parameters', icon: 'icon-68' },
          { title: ' Return Values From Functions', badge_list: true, question: 2, minutes: 15 },
          { title: ' Global Variable and Scope', icon: 'icon-68' },
          { title: 'Newer Way of creating a Constant', icon: 'icon-68' },
          { title: 'Constants', icon: 'icon-68' },
        ]
      },
    ],
    reviews:[
      {img:'/assets/images/blog/comment-01.jpg',rating:5,name:'Haley Bennet',date:'Oct 10, 2021',desc:'Lorem ipsum dolor sit amet, consectetur adipisicing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'},
      {img:'/assets/images/blog/comment-02.jpg',rating:5,name:'Simon Baker',date:'Oct 15, 2021',desc:'Lorem ipsum dolor sit amet, consectetur adipisicing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'},
      {img:'/assets/images/blog/comment-03.jpg',rating:5,name:'Richard Gere',date:'Oct 19, 2021',desc:'Lorem ipsum dolor sit amet, consectetur adipisicing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'},
    ]
  },
  // kitchen course
  {
    id: 14,
    kitchen_course:true,
    course_outline:'Online Only',
    duration: '16 Weeks',
    img: 'course-14.jpg',
    hours: 30,
    level: 'Cooking',
    title: 'Healthy Sushi Roll - Japanese Popular Cooking Class',
    rating: '5',
    rating_count: 35,
    course_price: '40',
    lesson: 22,
    student: 28,
    category:'Marketing',
    short_desc: 'Lorem ipsum dolor sit amet consectur adipiscing elit sed eiusmod tempor.',
    instructor: 'Susan White',
    instructor_img:'team-05.webp',
    instructor_title:'Founder & CEO',
    instructor_desc:'Consectetur adipisicing elit, sed do eiusmod tempor incididunt labore et dolore magna aliqua enim minim veniam quis nostrud exercitation ulla mco laboris nisi ut aliquip ex ea commodo consequat. duis aute irure dolor in reprehenderit in voluptate.',
    features: [
      'Professional Japanese cooking from beginners to experts',
      'Will be able to cook authentic Italian recipes in their own kitchen',
      'Understand the HOW of cooking, before thinking of the WHAT to cook.'
    ], 
    social_links:[
      {link: 'http://facebook.com', target: '_blank', icon: 'icon-facebook'},
      {link: 'http://twitter.com', target: '_blank', icon: 'icon-twitter'},
      {link: 'https://www.linkedin.com/', target: '_blank', icon: 'icon-linkedin2'},
      {link: 'https://www.youtube.com/', target: '_blank', icon: 'icon-youtube'}
    ],
    language: 'English',
    certificate: 'yes',
    videoId: 'PICj5tr9hcc',
    course_desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor inc idid unt ut labore et dolore magna aliqua enim ad minim veniam, quis nostrud exerec tation ullamco laboris nis aliquip commodo consequat duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur enim ipsam.',
    course_desc_2: 'Excepteur sint occaecat cupidatat non proident sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium totam rem aperiam.',
    learn_list: ['Professional Japanese cooking from beginners to experts','Will be able to cook authentic Italian recipes in their own kitchen','Understand the HOW of cooking, before thinking of the WHAT to cook.'],
    course_desc_3: 'Consectetur adipisicing elit, sed do eiusmod tempor inc idid unt ut labore et dolore magna aliqua enim ad minim veniam quis nostrud exerec tation ullamco laboris nis aliquip commodo consequat duis aute irure dolor.',
    curriculum_desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor inc idid unt ut labore et dolore magna aliqua enim ad minim veniam, quis nostrud exerec tation ullamco laboris nis aliquip commodo consequat.',
    course_lessons: [
      {
        title: 'Week 1-4',
        text: 'Advanced story telling techniques for writers: Personas, Characters & Plots',
        lessons: [
          { title: 'Introduction', icon: 'icon-68' },
          { title: 'Course Overview', icon: 'icon-68' },
          { title: 'Course Overview', badge_list: true, question: 0, minutes: 10 },
          { title: 'Course Exercise / Reference Files', icon: 'icon-68' },
          { title: 'Code Editor Installation (Optional if you have one)', icon: 'icon-68' },
          { title: ' Embedding PHP in HTML', icon: 'icon-68' },
        ]
      },
      {
        title: 'Week 5-8',
        text: 'Advanced story telling techniques for writers: Personas, Characters & Plots',
        lessons: [
          { title: 'Defining Functions', icon: 'icon-68' },
          { title: 'Function Parameters', icon: 'icon-68' },
          { title: ' Return Values From Functions', badge_list: true, question: 2, minutes: 15 },
          { title: ' Global Variable and Scope', icon: 'icon-68' },
          { title: 'Newer Way of creating a Constant', icon: 'icon-68' },
          { title: 'Constants', icon: 'icon-68' },
        ]
      },
    ],
    reviews:[
      {img:'/assets/images/blog/comment-01.jpg',rating:5,name:'Haley Bennet',date:'Oct 10, 2021',desc:'Lorem ipsum dolor sit amet, consectetur adipisicing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'},
      {img:'/assets/images/blog/comment-02.jpg',rating:5,name:'Simon Baker',date:'Oct 15, 2021',desc:'Lorem ipsum dolor sit amet, consectetur adipisicing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'},
      {img:'/assets/images/blog/comment-03.jpg',rating:5,name:'Richard Gere',date:'Oct 19, 2021',desc:'Lorem ipsum dolor sit amet, consectetur adipisicing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'},
    ]
  },
  {
    id: 15,
    kitchen_course:true,
    duration: '15 Weeks',
    course_outline:'Online + Onsite',
    img: 'course-15.jpg',
    hours: 32,
    level: 'Cooking',
    title: 'Nutrition Kitchen - Basics of Cooking for Busy People',
    rating: '4',
    rating_count: 38,
    course_price: '50',
    lesson: 15,
    student: 31,
    category:'Business',
    short_desc: 'Lorem ipsum dolor sit amet consectur adipiscing elit sed eiusmod tempor.',
    instructor: 'Penelope Cruz',
    instructor_img:'team-03.webp',
    instructor_title:'Founder & CEO',
    instructor_desc:'Consectetur adipisicing elit, sed do eiusmod tempor incididunt labore et dolore magna aliqua enim minim veniam quis nostrud exercitation ulla mco laboris nisi ut aliquip ex ea commodo consequat. duis aute irure dolor in reprehenderit in voluptate.',
    features: [
      'Prepare a huge variety of simple, delicious, healthy recipes.',
      'Professional Indian cooking from beginners to experts.',
      'Serve delicious and healthy meals for your loved ones.'
    ], 
    social_links:[
      {link: 'http://facebook.com', target: '_blank', icon: 'icon-facebook'},
      {link: 'http://twitter.com', target: '_blank', icon: 'icon-twitter'},
      {link: 'https://www.linkedin.com/', target: '_blank', icon: 'icon-linkedin2'},
      {link: 'https://www.youtube.com/', target: '_blank', icon: 'icon-youtube'}
    ],
    language: 'English',
    certificate: 'yes',
    videoId: 'PICj5tr9hcc',
    course_desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor inc idid unt ut labore et dolore magna aliqua enim ad minim veniam, quis nostrud exerec tation ullamco laboris nis aliquip commodo consequat duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur enim ipsam.',
    course_desc_2: 'Excepteur sint occaecat cupidatat non proident sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium totam rem aperiam.',
    learn_list: ['Professional Japanese cooking from beginners to experts','Will be able to cook authentic Italian recipes in their own kitchen','Understand the HOW of cooking, before thinking of the WHAT to cook.'],
    course_desc_3: 'Consectetur adipisicing elit, sed do eiusmod tempor inc idid unt ut labore et dolore magna aliqua enim ad minim veniam quis nostrud exerec tation ullamco laboris nis aliquip commodo consequat duis aute irure dolor.',
    curriculum_desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor inc idid unt ut labore et dolore magna aliqua enim ad minim veniam, quis nostrud exerec tation ullamco laboris nis aliquip commodo consequat.',
    course_lessons: [
      {
        title: 'Week 1-4',
        text: 'Advanced story telling techniques for writers: Personas, Characters & Plots',
        lessons: [
          { title: 'Introduction', icon: 'icon-68' },
          { title: 'Course Overview', icon: 'icon-68' },
          { title: 'Course Overview', badge_list: true, question: 0, minutes: 10 },
          { title: 'Course Exercise / Reference Files', icon: 'icon-68' },
          { title: 'Code Editor Installation (Optional if you have one)', icon: 'icon-68' },
          { title: ' Embedding PHP in HTML', icon: 'icon-68' },
        ]
      },
      {
        title: 'Week 5-8',
        text: 'Advanced story telling techniques for writers: Personas, Characters & Plots',
        lessons: [
          { title: 'Defining Functions', icon: 'icon-68' },
          { title: 'Function Parameters', icon: 'icon-68' },
          { title: ' Return Values From Functions', badge_list: true, question: 2, minutes: 15 },
          { title: ' Global Variable and Scope', icon: 'icon-68' },
          { title: 'Newer Way of creating a Constant', icon: 'icon-68' },
          { title: 'Constants', icon: 'icon-68' },
        ]
      },
    ],
    reviews:[
      {img:'/assets/images/blog/comment-01.jpg',rating:5,name:'Haley Bennet',date:'Oct 10, 2021',desc:'Lorem ipsum dolor sit amet, consectetur adipisicing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'},
      {img:'/assets/images/blog/comment-02.jpg',rating:5,name:'Simon Baker',date:'Oct 15, 2021',desc:'Lorem ipsum dolor sit amet, consectetur adipisicing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'},
      {img:'/assets/images/blog/comment-03.jpg',rating:5,name:'Richard Gere',date:'Oct 19, 2021',desc:'Lorem ipsum dolor sit amet, consectetur adipisicing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'},
    ]
  },
  {
    id: 16,
    kitchen_course:true,
    duration: '13 Weeks',
    course_outline:'Onsite Only',
    img: 'course-16.jpg',
    hours: 35,
    level: 'Cooking',
    title: 'Authentic Kerala Cooking - The South Indian Cuisine',
    rating: '5',
    rating_count: 40,
    course_price: '60',
    lesson: 20,
    student: 36,
    category:'Academics',
    short_desc: 'Lorem ipsum dolor sit amet consectur adipiscing elit sed eiusmod tempor.',
    instructor: 'Edward Norton',
    instructor_img:'team-02.webp',
    instructor_title:'Founder & CEO',
    instructor_desc:'Consectetur adipisicing elit, sed do eiusmod tempor incididunt labore et dolore magna aliqua enim minim veniam quis nostrud exercitation ulla mco laboris nisi ut aliquip ex ea commodo consequat. duis aute irure dolor in reprehenderit in voluptate.',
    features: [
      'Cook much loved recipes like ravioli, pizza and pesto from scratch',
      'Cook better than restaurant Thai food at home',
      'Keep your food safe from harmful bacteria and disease.'
    ], 
    social_links:[
      {link: 'http://facebook.com', target: '_blank', icon: 'icon-facebook'},
      {link: 'http://twitter.com', target: '_blank', icon: 'icon-twitter'},
      {link: 'https://www.linkedin.com/', target: '_blank', icon: 'icon-linkedin2'},
      {link: 'https://www.youtube.com/', target: '_blank', icon: 'icon-youtube'}
    ],
    language: 'English',
    certificate: 'yes',
    videoId: 'PICj5tr9hcc',
    course_desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor inc idid unt ut labore et dolore magna aliqua enim ad minim veniam, quis nostrud exerec tation ullamco laboris nis aliquip commodo consequat duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur enim ipsam.',
    course_desc_2: 'Excepteur sint occaecat cupidatat non proident sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium totam rem aperiam.',
    learn_list: ['Professional Japanese cooking from beginners to experts','Will be able to cook authentic Italian recipes in their own kitchen','Understand the HOW of cooking, before thinking of the WHAT to cook.'],
    course_desc_3: 'Consectetur adipisicing elit, sed do eiusmod tempor inc idid unt ut labore et dolore magna aliqua enim ad minim veniam quis nostrud exerec tation ullamco laboris nis aliquip commodo consequat duis aute irure dolor.',
    curriculum_desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor inc idid unt ut labore et dolore magna aliqua enim ad minim veniam, quis nostrud exerec tation ullamco laboris nis aliquip commodo consequat.',
    course_lessons: [
      {
        title: 'Week 1-4',
        text: 'Advanced story telling techniques for writers: Personas, Characters & Plots',
        lessons: [
          { title: 'Introduction', icon: 'icon-68' },
          { title: 'Course Overview', icon: 'icon-68' },
          { title: 'Course Overview', badge_list: true, question: 0, minutes: 10 },
          { title: 'Course Exercise / Reference Files', icon: 'icon-68' },
          { title: 'Code Editor Installation (Optional if you have one)', icon: 'icon-68' },
          { title: ' Embedding PHP in HTML', icon: 'icon-68' },
        ]
      },
      {
        title: 'Week 5-8',
        text: 'Advanced story telling techniques for writers: Personas, Characters & Plots',
        lessons: [
          { title: 'Defining Functions', icon: 'icon-68' },
          { title: 'Function Parameters', icon: 'icon-68' },
          { title: ' Return Values From Functions', badge_list: true, question: 2, minutes: 15 },
          { title: ' Global Variable and Scope', icon: 'icon-68' },
          { title: 'Newer Way of creating a Constant', icon: 'icon-68' },
          { title: 'Constants', icon: 'icon-68' },
        ]
      },
    ],
    reviews:[
      {img:'/assets/images/blog/comment-01.jpg',rating:5,name:'Haley Bennet',date:'Oct 10, 2021',desc:'Lorem ipsum dolor sit amet, consectetur adipisicing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'},
      {img:'/assets/images/blog/comment-02.jpg',rating:5,name:'Simon Baker',date:'Oct 15, 2021',desc:'Lorem ipsum dolor sit amet, consectetur adipisicing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'},
      {img:'/assets/images/blog/comment-03.jpg',rating:5,name:'Richard Gere',date:'Oct 19, 2021',desc:'Lorem ipsum dolor sit amet, consectetur adipisicing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'},
    ]
  },
  {
    id: 17,
    kitchen_course:true,
    duration: '20 Weeks',
    course_outline:'Online + Onsite',
    img: 'course-17.jpg',
    hours: 40,
    level: 'Cooking',
    level: 'Drawing',
    title: 'Vegan Thai Cooking Classes Popular Vegan Recipes',
    rating: '5',
    rating_count: 35,
    course_price: '30',
    lesson: 22,
    student: 40,
    category:'Development',
    short_desc: 'Lorem ipsum dolor sit amet consectur elit sed eiusmod ex tempor incididunt labore dolore magna.',
    instructor: 'Edward Norton',
    instructor_img:'team-02.webp',
    instructor_title:'Founder & CEO',
    instructor_desc:'Consectetur adipisicing elit, sed do eiusmod tempor incididunt labore et dolore magna aliqua enim minim veniam quis nostrud exercitation ulla mco laboris nisi ut aliquip ex ea commodo consequat. duis aute irure dolor in reprehenderit in voluptate.',
    features: [
      'Professional Indian cooking from beginners to experts.',
      'Prepare a huge variety of simple, delicious, healthy recipes.',
      'Serve delicious and healthy meals for your loved ones.'
    ],
    social_links:[
      {link: 'http://facebook.com', target: '_blank', icon: 'icon-facebook'},
      {link: 'http://twitter.com', target: '_blank', icon: 'icon-twitter'},
      {link: 'https://www.linkedin.com/', target: '_blank', icon: 'icon-linkedin2'},
      {link: 'https://www.youtube.com/', target: '_blank', icon: 'icon-youtube'}
    ],
    language: 'English',
    certificate: 'yes',
    videoId: 'PICj5tr9hcc',
    course_desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor inc idid unt ut labore et dolore magna aliqua enim ad minim veniam, quis nostrud exerec tation ullamco laboris nis aliquip commodo consequat duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur enim ipsam.',
    course_desc_2: 'Excepteur sint occaecat cupidatat non proident sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium totam rem aperiam.',
    learn_list: ['Professional Japanese cooking from beginners to experts','Will be able to cook authentic Italian recipes in their own kitchen','Understand the HOW of cooking, before thinking of the WHAT to cook.'],
    course_desc_3: 'Consectetur adipisicing elit, sed do eiusmod tempor inc idid unt ut labore et dolore magna aliqua enim ad minim veniam quis nostrud exerec tation ullamco laboris nis aliquip commodo consequat duis aute irure dolor.',
    curriculum_desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor inc idid unt ut labore et dolore magna aliqua enim ad minim veniam, quis nostrud exerec tation ullamco laboris nis aliquip commodo consequat.',
    course_lessons: [
      {
        title: 'Week 1-4',
        text: 'Advanced story telling techniques for writers: Personas, Characters & Plots',
        lessons: [
          { title: 'Introduction', icon: 'icon-68' },
          { title: 'Course Overview', icon: 'icon-68' },
          { title: 'Course Overview', badge_list: true, question: 0, minutes: 10 },
          { title: 'Course Exercise / Reference Files', icon: 'icon-68' },
          { title: 'Code Editor Installation (Optional if you have one)', icon: 'icon-68' },
          { title: ' Embedding PHP in HTML', icon: 'icon-68' },
        ]
      },
      {
        title: 'Week 5-8',
        text: 'Advanced story telling techniques for writers: Personas, Characters & Plots',
        lessons: [
          { title: 'Defining Functions', icon: 'icon-68' },
          { title: 'Function Parameters', icon: 'icon-68' },
          { title: ' Return Values From Functions', badge_list: true, question: 2, minutes: 15 },
          { title: ' Global Variable and Scope', icon: 'icon-68' },
          { title: 'Newer Way of creating a Constant', icon: 'icon-68' },
          { title: 'Constants', icon: 'icon-68' },
        ]
      },
    ],
    reviews:[
      {img:'/assets/images/blog/comment-01.jpg',rating:5,name:'Haley Bennet',date:'Oct 10, 2021',desc:'Lorem ipsum dolor sit amet, consectetur adipisicing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'},
      {img:'/assets/images/blog/comment-02.jpg',rating:5,name:'Simon Baker',date:'Oct 15, 2021',desc:'Lorem ipsum dolor sit amet, consectetur adipisicing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'},
      {img:'/assets/images/blog/comment-03.jpg',rating:5,name:'Richard Gere',date:'Oct 19, 2021',desc:'Lorem ipsum dolor sit amet, consectetur adipisicing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'},
    ]
  },
  {
    id: 18,
    kitchen_course:true,
    duration: '15 Weeks',
    course_outline:'Online + Onsite',
    img: 'course-18.jpg',
    hours: 32,
    level: 'Cooking',
    title: 'Indian Culinary World - Master the art of Indian Cooking',
    rating: '4',
    rating_count: 38,
    course_price: '50',
    lesson: 15,
    student: 31,
    category:'Business',
    short_desc: 'Lorem ipsum dolor sit amet consectur adipiscing elit sed eiusmod tempor.',
    instructor: 'Susan White',
    instructor_img:'team-05.webp',
    instructor_title:'Founder & CEO',
    instructor_desc:'Consectetur adipisicing elit, sed do eiusmod tempor incididunt labore et dolore magna aliqua enim minim veniam quis nostrud exercitation ulla mco laboris nisi ut aliquip ex ea commodo consequat. duis aute irure dolor in reprehenderit in voluptate.',
    features: [
      'Prepare a huge variety of simple, delicious, healthy recipes.',
      'Professional Indian cooking from beginners to experts.',
      'Serve delicious and healthy meals for your loved ones.'
    ], 
    social_links:[
      {link: 'http://facebook.com', target: '_blank', icon: 'icon-facebook'},
      {link: 'http://twitter.com', target: '_blank', icon: 'icon-twitter'},
      {link: 'https://www.linkedin.com/', target: '_blank', icon: 'icon-linkedin2'},
      {link: 'https://www.youtube.com/', target: '_blank', icon: 'icon-youtube'}
    ],
    language: 'English',
    certificate: 'yes',
    videoId: 'PICj5tr9hcc',
    course_desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor inc idid unt ut labore et dolore magna aliqua enim ad minim veniam, quis nostrud exerec tation ullamco laboris nis aliquip commodo consequat duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur enim ipsam.',
    course_desc_2: 'Excepteur sint occaecat cupidatat non proident sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium totam rem aperiam.',
    learn_list: ['Professional Japanese cooking from beginners to experts','Will be able to cook authentic Italian recipes in their own kitchen','Understand the HOW of cooking, before thinking of the WHAT to cook.'],
    course_desc_3: 'Consectetur adipisicing elit, sed do eiusmod tempor inc idid unt ut labore et dolore magna aliqua enim ad minim veniam quis nostrud exerec tation ullamco laboris nis aliquip commodo consequat duis aute irure dolor.',
    curriculum_desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor inc idid unt ut labore et dolore magna aliqua enim ad minim veniam, quis nostrud exerec tation ullamco laboris nis aliquip commodo consequat.',
    course_lessons: [
      {
        title: 'Week 1-4',
        text: 'Advanced story telling techniques for writers: Personas, Characters & Plots',
        lessons: [
          { title: 'Introduction', icon: 'icon-68' },
          { title: 'Course Overview', icon: 'icon-68' },
          { title: 'Course Overview', badge_list: true, question: 0, minutes: 10 },
          { title: 'Course Exercise / Reference Files', icon: 'icon-68' },
          { title: 'Code Editor Installation (Optional if you have one)', icon: 'icon-68' },
          { title: ' Embedding PHP in HTML', icon: 'icon-68' },
        ]
      },
      {
        title: 'Week 5-8',
        text: 'Advanced story telling techniques for writers: Personas, Characters & Plots',
        lessons: [
          { title: 'Defining Functions', icon: 'icon-68' },
          { title: 'Function Parameters', icon: 'icon-68' },
          { title: ' Return Values From Functions', badge_list: true, question: 2, minutes: 15 },
          { title: ' Global Variable and Scope', icon: 'icon-68' },
          { title: 'Newer Way of creating a Constant', icon: 'icon-68' },
          { title: 'Constants', icon: 'icon-68' },
        ]
      },
    ],
    reviews:[
      {img:'/assets/images/blog/comment-01.jpg',rating:5,name:'Haley Bennet',date:'Oct 10, 2021',desc:'Lorem ipsum dolor sit amet, consectetur adipisicing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'},
      {img:'/assets/images/blog/comment-02.jpg',rating:5,name:'Simon Baker',date:'Oct 15, 2021',desc:'Lorem ipsum dolor sit amet, consectetur adipisicing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'},
      {img:'/assets/images/blog/comment-03.jpg',rating:5,name:'Richard Gere',date:'Oct 19, 2021',desc:'Lorem ipsum dolor sit amet, consectetur adipisicing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'},
    ]
  },
  {
    id: 19,
    kitchen_course:true,
    duration: '13 Weeks',
    course_outline:'Onsite Only',
    img: 'course-19.jpg',
    hours: 35,
    level: 'Cooking',
    title: 'Herbalism - Medicinal Kitchen Herbs & Spices Certificate',
    rating: '5',
    rating_count: 40,
    course_price: '60',
    lesson: 20,
    student: 36,
    category:'Academics',
    short_desc: 'Lorem ipsum dolor sit amet consectur adipiscing elit sed eiusmod tempor.',
    instructor: 'Jane Seymour',
    instructor_img:'team-01.webp',
    instructor_title:'Founder & CEO',
    instructor_desc:'Consectetur adipisicing elit, sed do eiusmod tempor incididunt labore et dolore magna aliqua enim minim veniam quis nostrud exercitation ulla mco laboris nisi ut aliquip ex ea commodo consequat. duis aute irure dolor in reprehenderit in voluptate.',
    features: [
      'Cook much loved recipes like ravioli, pizza and pesto from scratch',
      'Cook better than restaurant Thai food at home',
      'Keep your food safe from harmful bacteria and disease.'
    ], 
    social_links:[
      {link: 'http://facebook.com', target: '_blank', icon: 'icon-facebook'},
      {link: 'http://twitter.com', target: '_blank', icon: 'icon-twitter'},
      {link: 'https://www.linkedin.com/', target: '_blank', icon: 'icon-linkedin2'},
      {link: 'https://www.youtube.com/', target: '_blank', icon: 'icon-youtube'}
    ],
    language: 'English',
    certificate: 'yes',
    videoId: 'PICj5tr9hcc',
    course_desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor inc idid unt ut labore et dolore magna aliqua enim ad minim veniam, quis nostrud exerec tation ullamco laboris nis aliquip commodo consequat duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur enim ipsam.',
    course_desc_2: 'Excepteur sint occaecat cupidatat non proident sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium totam rem aperiam.',
    learn_list: ['Professional Japanese cooking from beginners to experts','Will be able to cook authentic Italian recipes in their own kitchen','Understand the HOW of cooking, before thinking of the WHAT to cook.'],
    course_desc_3: 'Consectetur adipisicing elit, sed do eiusmod tempor inc idid unt ut labore et dolore magna aliqua enim ad minim veniam quis nostrud exerec tation ullamco laboris nis aliquip commodo consequat duis aute irure dolor.',
    curriculum_desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor inc idid unt ut labore et dolore magna aliqua enim ad minim veniam, quis nostrud exerec tation ullamco laboris nis aliquip commodo consequat.',
    course_lessons: [
      {
        title: 'Week 1-4',
        text: 'Advanced story telling techniques for writers: Personas, Characters & Plots',
        lessons: [
          { title: 'Introduction', icon: 'icon-68' },
          { title: 'Course Overview', icon: 'icon-68' },
          { title: 'Course Overview', badge_list: true, question: 0, minutes: 10 },
          { title: 'Course Exercise / Reference Files', icon: 'icon-68' },
          { title: 'Code Editor Installation (Optional if you have one)', icon: 'icon-68' },
          { title: ' Embedding PHP in HTML', icon: 'icon-68' },
        ]
      },
      {
        title: 'Week 5-8',
        text: 'Advanced story telling techniques for writers: Personas, Characters & Plots',
        lessons: [
          { title: 'Defining Functions', icon: 'icon-68' },
          { title: 'Function Parameters', icon: 'icon-68' },
          { title: ' Return Values From Functions', badge_list: true, question: 2, minutes: 15 },
          { title: ' Global Variable and Scope', icon: 'icon-68' },
          { title: 'Newer Way of creating a Constant', icon: 'icon-68' },
          { title: 'Constants', icon: 'icon-68' },
        ]
      },
    ],
    reviews:[
      {img:'/assets/images/blog/comment-01.jpg',rating:5,name:'Haley Bennet',date:'Oct 10, 2021',desc:'Lorem ipsum dolor sit amet, consectetur adipisicing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'},
      {img:'/assets/images/blog/comment-02.jpg',rating:5,name:'Simon Baker',date:'Oct 15, 2021',desc:'Lorem ipsum dolor sit amet, consectetur adipisicing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'},
      {img:'/assets/images/blog/comment-03.jpg',rating:5,name:'Richard Gere',date:'Oct 19, 2021',desc:'Lorem ipsum dolor sit amet, consectetur adipisicing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'},
    ]
  },
  {
    id: 20,
    yoga_course: true,
    img: 'course-20.jpg',
    duration: '9 Weeks',
    course_outline:'Onsite Only',
    level: 'Power Yoga',
    title: 'Yoga for Back Pain Relief',
    rating: '4.9',
    rating_count: 30,
    course_price: '30',
    lesson: 12,
    student: 85,
    category:'Marketing',
    short_desc: 'Lorem ipsum dolor sit amet consectur adipiscing elit sed eiusmod tempor.',
    instructor: 'Edward Norton',
    instructor_img:'team-02.webp',
    instructor_title:'Founder & CEO',
    instructor_desc:'Consectetur adipisicing elit, sed do eiusmod tempor incididunt labore et dolore magna aliqua enim minim veniam quis nostrud exercitation ulla mco laboris nisi ut aliquip ex ea commodo consequat. duis aute irure dolor in reprehenderit in voluptate.',
    features: [
      'Build fully-fledged websites and web apps for your business.',
      'After the course you will be able to build ANY website you want.',
      'You will master both front and back-end development technologies.'
    ], 
    social_links:[
      {link: 'http://facebook.com', target: '_blank', icon: 'icon-facebook'},
      {link: 'http://twitter.com', target: '_blank', icon: 'icon-twitter'},
      {link: 'https://www.linkedin.com/', target: '_blank', icon: 'icon-linkedin2'},
      {link: 'https://www.youtube.com/', target: '_blank', icon: 'icon-youtube'}
    ],
    language: 'English',
    certificate: 'yes',
    videoId: 'PICj5tr9hcc',
    course_desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor inc idid unt ut labore et dolore magna aliqua enim ad minim veniam, quis nostrud exerec tation ullamco laboris nis aliquip commodo consequat duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur enim ipsam.',
    course_desc_2: 'Excepteur sint occaecat cupidatat non proident sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium totam rem aperiam.',
    learn_list: ['Learn to use Python professionally, learning both Python 2 & Python 3!', 'Build 6 beautiful real-world projects for your portfolio (not boring toy apps)', 'Understand the Theory behind Vue.js and use it in Real Projects', 'Create responsive, accessible, and beautiful layouts'],
    course_desc_3: 'Consectetur adipisicing elit, sed do eiusmod tempor inc idid unt ut labore et dolore magna aliqua enim ad minim veniam quis nostrud exerec tation ullamco laboris nis aliquip commodo consequat duis aute irure dolor.',
    curriculum_desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor inc idid unt ut labore et dolore magna aliqua enim ad minim veniam, quis nostrud exerec tation ullamco laboris nis aliquip commodo consequat.',
    course_lessons: [
      {
        title: 'Week 1-4',
        text: 'Advanced story telling techniques for writers: Personas, Characters & Plots',
        lessons: [
          { title: 'Introduction', icon: 'icon-68' },
          { title: 'Course Overview', icon: 'icon-68' },
          { title: 'Course Overview', badge_list: true, question: 0, minutes: 10 },
          { title: 'Course Exercise / Reference Files', icon: 'icon-68' },
          { title: 'Code Editor Installation (Optional if you have one)', icon: 'icon-68' },
          { title: ' Embedding PHP in HTML', icon: 'icon-68' },
        ]
      },
      {
        title: 'Week 5-8',
        text: 'Advanced story telling techniques for writers: Personas, Characters & Plots',
        lessons: [
          { title: 'Defining Functions', icon: 'icon-68' },
          { title: 'Function Parameters', icon: 'icon-68' },
          { title: ' Return Values From Functions', badge_list: true, question: 2, minutes: 15 },
          { title: ' Global Variable and Scope', icon: 'icon-68' },
          { title: 'Newer Way of creating a Constant', icon: 'icon-68' },
          { title: 'Constants', icon: 'icon-68' },
        ]
      },
    ],
    reviews:[
      {img:'/assets/images/blog/comment-01.jpg',rating:5,name:'Haley Bennet',date:'Oct 10, 2021',desc:'Lorem ipsum dolor sit amet, consectetur adipisicing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'},
      {img:'/assets/images/blog/comment-02.jpg',rating:5,name:'Simon Baker',date:'Oct 15, 2021',desc:'Lorem ipsum dolor sit amet, consectetur adipisicing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'},
      {img:'/assets/images/blog/comment-03.jpg',rating:5,name:'Richard Gere',date:'Oct 19, 2021',desc:'Lorem ipsum dolor sit amet, consectetur adipisicing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'},
    ]
  },
  {
    id: 21,
    yoga_course: true,
    img: 'course-21.jpg',
    duration: '5 Weeks',
    course_outline:'Online + Onsite',
    level: 'Power Yoga',
    title: 'Super Brain YOGA',
    rating: '5.0',
    rating_count: 11,
    course_price: '40',
    lesson: 20,
    student: 38,
    category:'Art & Design',
    short_desc: 'Lorem ipsum dolor sit amet consectur elit sed eiusmod ex tempor incididunt labore dolore magna.',
    instructor: 'John Travolta',
    instructor_img:'team-04.webp',
    instructor_title:'Founder & CEO',
    instructor_desc:'Consectetur adipisicing elit, sed do eiusmod tempor incididunt labore et dolore magna aliqua enim minim veniam quis nostrud exercitation ulla mco laboris nisi ut aliquip ex ea commodo consequat. duis aute irure dolor in reprehenderit in voluptate.',
    features: [
      'Build fully-fledged websites and web apps for your business.',
      'After the course you will be able to build ANY website you want.',
      'You will master both front and back-end development technologies.'
    ], 
    social_links:[
      {link: 'http://facebook.com', target: '_blank', icon: 'icon-facebook'},
      {link: 'http://twitter.com', target: '_blank', icon: 'icon-twitter'},
      {link: 'https://www.linkedin.com/', target: '_blank', icon: 'icon-linkedin2'},
      {link: 'https://www.youtube.com/', target: '_blank', icon: 'icon-youtube'}
    ],
    language:'Hindi',
    certificate:'yes',
    videoId:'c238xxeDdFI',
    course_desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor inc idid unt ut labore et dolore magna aliqua enim ad minim veniam, quis nostrud exerec tation ullamco laboris nis aliquip commodo consequat duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur enim ipsam.',
    course_desc_2: 'Excepteur sint occaecat cupidatat non proident sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium totam rem aperiam.',
    learn_list: ['Learn to use Python professionally, learning both Python 2 & Python 3!', 'Build 6 beautiful real-world projects for your portfolio (not boring toy apps)', 'Understand the Theory behind Vue.js and use it in Real Projects', 'Create responsive, accessible, and beautiful layouts'],
    course_desc_3: 'Consectetur adipisicing elit, sed do eiusmod tempor inc idid unt ut labore et dolore magna aliqua enim ad minim veniam quis nostrud exerec tation ullamco laboris nis aliquip commodo consequat duis aute irure dolor.',
    curriculum_desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor inc idid unt ut labore et dolore magna aliqua enim ad minim veniam, quis nostrud exerec tation ullamco laboris nis aliquip commodo consequat.',
    course_lessons: [
      {
        title: 'Week 1-4',
        text: 'Advanced story telling techniques for writers: Personas, Characters & Plots',
        lessons: [
          { title: 'Introduction', icon: 'icon-68' },
          { title: 'Course Overview', icon: 'icon-68' },
          { title: 'Course Overview', badge_list: true, question: 0, minutes: 10 },
          { title: 'Course Exercise / Reference Files', icon: 'icon-68' },
          { title: 'Code Editor Installation (Optional if you have one)', icon: 'icon-68' },
          { title: ' Embedding PHP in HTML', icon: 'icon-68' },
        ]
      },
      {
        title: 'Week 5-8',
        text: 'Advanced story telling techniques for writers: Personas, Characters & Plots',
        lessons: [
          { title: 'Defining Functions', icon: 'icon-68' },
          { title: 'Function Parameters', icon: 'icon-68' },
          { title: ' Return Values From Functions', badge_list: true, question: 2, minutes: 15 },
          { title: ' Global Variable and Scope', icon: 'icon-68' },
          { title: 'Newer Way of creating a Constant', icon: 'icon-68' },
          { title: 'Constants', icon: 'icon-68' },
        ]
      },
    ],
    reviews:[
      {img:'/assets/images/blog/comment-01.jpg',rating:5,name:'Haley Bennet',date:'Oct 10, 2021',desc:'Lorem ipsum dolor sit amet, consectetur adipisicing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'},
      {img:'/assets/images/blog/comment-02.jpg',rating:5,name:'Simon Baker',date:'Oct 15, 2021',desc:'Lorem ipsum dolor sit amet, consectetur adipisicing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'},
      {img:'/assets/images/blog/comment-03.jpg',rating:5,name:'Richard Gere',date:'Oct 19, 2021',desc:'Lorem ipsum dolor sit amet, consectetur adipisicing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'},
    ]
  },
  {
    id: 22,
    yoga_course:true,
    img: 'course-22.jpg',
    duration: '10 Weeks',
    course_outline:'Online + Onsite',
    level: 'Power Yoga',
    title: 'Chair Yoga for Seniors',
    rating: '4.9',
    rating_count: 15,
    course_price: '50',
    lesson: 20,
    student: 35,
    category:'Development',
    short_desc: 'Lorem ipsum dolor sit amet consectur elit sed eiusmod ex tempor incididunt labore dolore magna.',
    instructor: 'John Travolta',
    instructor_img:'team-04.webp',
    instructor_title:'Founder & CEO',
    instructor_desc:'Consectetur adipisicing elit, sed do eiusmod tempor incididunt labore et dolore magna aliqua enim minim veniam quis nostrud exercitation ulla mco laboris nisi ut aliquip ex ea commodo consequat. duis aute irure dolor in reprehenderit in voluptate.',
    features: [
      'Build fully-fledged websites and web apps for your business.',
      'After the course you will be able to build ANY website you want.',
      'You will master both front and back-end development technologies.'
    ], 
    social_links:[
      {link: 'http://facebook.com', target: '_blank', icon: 'icon-facebook'},
      {link: 'http://twitter.com', target: '_blank', icon: 'icon-twitter'},
      {link: 'https://www.linkedin.com/', target: '_blank', icon: 'icon-linkedin2'},
      {link: 'https://www.youtube.com/', target: '_blank', icon: 'icon-youtube'}
    ],
    language: 'English',
    certificate: 'yes',
    videoId: 'PICj5tr9hcc',
    course_desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor inc idid unt ut labore et dolore magna aliqua enim ad minim veniam, quis nostrud exerec tation ullamco laboris nis aliquip commodo consequat duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur enim ipsam.',
    course_desc_2: 'Excepteur sint occaecat cupidatat non proident sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium totam rem aperiam.',
    learn_list: ['Learn to use Python professionally, learning both Python 2 & Python 3!', 'Build 6 beautiful real-world projects for your portfolio (not boring toy apps)', 'Understand the Theory behind Vue.js and use it in Real Projects', 'Create responsive, accessible, and beautiful layouts'],
    course_desc_3: 'Consectetur adipisicing elit, sed do eiusmod tempor inc idid unt ut labore et dolore magna aliqua enim ad minim veniam quis nostrud exerec tation ullamco laboris nis aliquip commodo consequat duis aute irure dolor.',
    curriculum_desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor inc idid unt ut labore et dolore magna aliqua enim ad minim veniam, quis nostrud exerec tation ullamco laboris nis aliquip commodo consequat.',
    course_lessons: [
      {
        title: 'Week 1-4',
        text: 'Advanced story telling techniques for writers: Personas, Characters & Plots',
        lessons: [
          { title: 'Introduction', icon: 'icon-68' },
          { title: 'Course Overview', icon: 'icon-68' },
          { title: 'Course Overview', badge_list: true, question: 0, minutes: 10 },
          { title: 'Course Exercise / Reference Files', icon: 'icon-68' },
          { title: 'Code Editor Installation (Optional if you have one)', icon: 'icon-68' },
          { title: ' Embedding PHP in HTML', icon: 'icon-68' },
        ]
      },
      {
        title: 'Week 5-8',
        text: 'Advanced story telling techniques for writers: Personas, Characters & Plots',
        lessons: [
          { title: 'Defining Functions', icon: 'icon-68' },
          { title: 'Function Parameters', icon: 'icon-68' },
          { title: ' Return Values From Functions', badge_list: true, question: 2, minutes: 15 },
          { title: ' Global Variable and Scope', icon: 'icon-68' },
          { title: 'Newer Way of creating a Constant', icon: 'icon-68' },
          { title: 'Constants', icon: 'icon-68' },
        ]
      },
    ],
    reviews:[
      {img:'/assets/images/blog/comment-01.jpg',rating:5,name:'Haley Bennet',date:'Oct 10, 2021',desc:'Lorem ipsum dolor sit amet, consectetur adipisicing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'},
      {img:'/assets/images/blog/comment-02.jpg',rating:5,name:'Simon Baker',date:'Oct 15, 2021',desc:'Lorem ipsum dolor sit amet, consectetur adipisicing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'},
      {img:'/assets/images/blog/comment-03.jpg',rating:5,name:'Richard Gere',date:'Oct 19, 2021',desc:'Lorem ipsum dolor sit amet, consectetur adipisicing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'},
    ]
  },
  {
    id: 23,
    yoga_course: true,
    img: 'course-23.jpg',
    duration: '3 Weeks',
    level: 'Power Yoga',
    course_outline:'Online Only',
    title: 'Online Yoga for Beginners',
    rating: '4.4',
    rating_count: 10,
    course_price: '60',
    lesson: 15,
    student: 35,
    category:'Art & Design',
    short_desc: 'Lorem ipsum dolor sit amet consectur elit sed eiusmod ex tempor incididunt labore dolore magna.',
    instructor: 'John Travolta',
    instructor_img:'team-04.webp',
    instructor_title:'Founder & CEO',
    instructor_desc:'Consectetur adipisicing elit, sed do eiusmod tempor incididunt labore et dolore magna aliqua enim minim veniam quis nostrud exercitation ulla mco laboris nisi ut aliquip ex ea commodo consequat. duis aute irure dolor in reprehenderit in voluptate.',
    features: [
      'Build fully-fledged websites and web apps for your business.',
      'After the course you will be able to build ANY website you want.',
      'You will master both front and back-end development technologies.'
    ], 
    social_links:[
      {link: 'http://facebook.com', target: '_blank', icon: 'icon-facebook'},
      {link: 'http://twitter.com', target: '_blank', icon: 'icon-twitter'},
      {link: 'https://www.linkedin.com/', target: '_blank', icon: 'icon-linkedin2'},
      {link: 'https://www.youtube.com/', target: '_blank', icon: 'icon-youtube'}
    ],
    language: 'Korean',
    certificate: 'yes',
    videoId: 'PICj5tr9hcc',
    course_desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor inc idid unt ut labore et dolore magna aliqua enim ad minim veniam, quis nostrud exerec tation ullamco laboris nis aliquip commodo consequat duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur enim ipsam.',
    course_desc_2: 'Excepteur sint occaecat cupidatat non proident sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium totam rem aperiam.',
    learn_list: ['Learn to use Python professionally, learning both Python 2 & Python 3!', 'Build 6 beautiful real-world projects for your portfolio (not boring toy apps)', 'Understand the Theory behind Vue.js and use it in Real Projects', 'Create responsive, accessible, and beautiful layouts'],
    course_desc_3: 'Consectetur adipisicing elit, sed do eiusmod tempor inc idid unt ut labore et dolore magna aliqua enim ad minim veniam quis nostrud exerec tation ullamco laboris nis aliquip commodo consequat duis aute irure dolor.',
    curriculum_desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor inc idid unt ut labore et dolore magna aliqua enim ad minim veniam, quis nostrud exerec tation ullamco laboris nis aliquip commodo consequat.',
    course_lessons: [
      {
        title: 'Week 1-4',
        text: 'Advanced story telling techniques for writers: Personas, Characters & Plots',
        lessons: [
          { title: 'Introduction', icon: 'icon-68' },
          { title: 'Course Overview', icon: 'icon-68' },
          { title: 'Course Overview', badge_list: true, question: 0, minutes: 10 },
          { title: 'Course Exercise / Reference Files', icon: 'icon-68' },
          { title: 'Code Editor Installation (Optional if you have one)', icon: 'icon-68' },
          { title: ' Embedding PHP in HTML', icon: 'icon-68' },
        ]
      },
      {
        title: 'Week 5-8',
        text: 'Advanced story telling techniques for writers: Personas, Characters & Plots',
        lessons: [
          { title: 'Defining Functions', icon: 'icon-68' },
          { title: 'Function Parameters', icon: 'icon-68' },
          { title: ' Return Values From Functions', badge_list: true, question: 2, minutes: 15 },
          { title: ' Global Variable and Scope', icon: 'icon-68' },
          { title: 'Newer Way of creating a Constant', icon: 'icon-68' },
          { title: 'Constants', icon: 'icon-68' },
        ]
      },
    ],
    reviews:[
      {img:'/assets/images/blog/comment-01.jpg',rating:5,name:'Haley Bennet',date:'Oct 10, 2021',desc:'Lorem ipsum dolor sit amet, consectetur adipisicing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'},
      {img:'/assets/images/blog/comment-02.jpg',rating:5,name:'Simon Baker',date:'Oct 15, 2021',desc:'Lorem ipsum dolor sit amet, consectetur adipisicing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'},
      {img:'/assets/images/blog/comment-03.jpg',rating:5,name:'Richard Gere',date:'Oct 19, 2021',desc:'Lorem ipsum dolor sit amet, consectetur adipisicing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'},
    ]
  },
  {
    id: 24,
    yoga_course: true,
    duration: '12 Weeks',
    course_outline:'Online Only',
    img: 'course-24.jpg',
    hours: 35,
    level: 'Chair Yoga',
    title: 'Yoga Nidra Master Class',
    rating: '5',
    rating_count: 8,
    course_price: '70',
    lesson: 13,
    student: 35,
    category:'Art & Design',
    short_desc: 'Lorem ipsum dolor sit amet consectur adipiscing elit sed eiusmod tempor.',
    instructor: 'Jane Seymour',
    instructor_img:'team-01.webp',
    instructor_title:'Founder & CEO',
    instructor_desc:'Consectetur adipisicing elit, sed do eiusmod tempor incididunt labore et dolore magna aliqua enim minim veniam quis nostrud exercitation ulla mco laboris nisi ut aliquip ex ea commodo consequat. duis aute irure dolor in reprehenderit in voluptate.',
    features: [
      'Build fully-fledged websites and web apps for your business.',
      'After the course you will be able to build ANY website you want.',
      'You will master both front and back-end development technologies.'
    ], 
    social_links:[
      {link: 'http://facebook.com', target: '_blank', icon: 'icon-facebook'},
      {link: 'http://twitter.com', target: '_blank', icon: 'icon-twitter'},
      {link: 'https://www.linkedin.com/', target: '_blank', icon: 'icon-linkedin2'},
      {link: 'https://www.youtube.com/', target: '_blank', icon: 'icon-youtube'}
    ],
    language: 'English',
    certificate: 'yes',
    videoId: 'PICj5tr9hcc',
    course_desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor inc idid unt ut labore et dolore magna aliqua enim ad minim veniam, quis nostrud exerec tation ullamco laboris nis aliquip commodo consequat duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur enim ipsam.',
    course_desc_2: 'Excepteur sint occaecat cupidatat non proident sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium totam rem aperiam.',
    learn_list: ['Professional Japanese cooking from beginners to experts','Will be able to cook authentic Italian recipes in their own kitchen','Understand the HOW of cooking, before thinking of the WHAT to cook.'],
    course_desc_3: 'Consectetur adipisicing elit, sed do eiusmod tempor inc idid unt ut labore et dolore magna aliqua enim ad minim veniam quis nostrud exerec tation ullamco laboris nis aliquip commodo consequat duis aute irure dolor.',
    curriculum_desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor inc idid unt ut labore et dolore magna aliqua enim ad minim veniam, quis nostrud exerec tation ullamco laboris nis aliquip commodo consequat.',
    course_lessons: [
      {
        title: 'Week 1-4',
        text: 'Advanced story telling techniques for writers: Personas, Characters & Plots',
        lessons: [
          { title: 'Introduction', icon: 'icon-68' },
          { title: 'Course Overview', icon: 'icon-68' },
          { title: 'Course Overview', badge_list: true, question: 0, minutes: 10 },
          { title: 'Course Exercise / Reference Files', icon: 'icon-68' },
          { title: 'Code Editor Installation (Optional if you have one)', icon: 'icon-68' },
          { title: ' Embedding PHP in HTML', icon: 'icon-68' },
        ]
      },
      {
        title: 'Week 5-8',
        text: 'Advanced story telling techniques for writers: Personas, Characters & Plots',
        lessons: [
          { title: 'Defining Functions', icon: 'icon-68' },
          { title: 'Function Parameters', icon: 'icon-68' },
          { title: ' Return Values From Functions', badge_list: true, question: 2, minutes: 15 },
          { title: ' Global Variable and Scope', icon: 'icon-68' },
          { title: 'Newer Way of creating a Constant', icon: 'icon-68' },
          { title: 'Constants', icon: 'icon-68' },
        ]
      },
    ],
    reviews:[
      {img:'/assets/images/blog/comment-01.jpg',rating:5,name:'Haley Bennet',date:'Oct 10, 2021',desc:'Lorem ipsum dolor sit amet, consectetur adipisicing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'},
      {img:'/assets/images/blog/comment-02.jpg',rating:5,name:'Simon Baker',date:'Oct 15, 2021',desc:'Lorem ipsum dolor sit amet, consectetur adipisicing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'},
      {img:'/assets/images/blog/comment-03.jpg',rating:5,name:'Richard Gere',date:'Oct 19, 2021',desc:'Lorem ipsum dolor sit amet, consectetur adipisicing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'},
    ]
  },
  {
    id: 25,
    yoga_course: true,
    duration: '9 Weeks',
    course_outline:'Online Only',
    img: 'course-25.jpg',
    hours: 45,
    level: 'Chair Yoga',
    title: 'Gentle Seated Chair Yoga',
    rating: '4.8',
    rating_count: 16,
    course_price: '80',
    lesson: 18,
    student: 42,
    category:'Art & Design',
    short_desc: 'Lorem ipsum dolor sit amet consectur adipiscing elit sed eiusmod tempor.',
    instructor: 'Jane Seymour',
    instructor_img:'team-01.webp',
    instructor_title:'Founder & CEO',
    instructor_desc:'Consectetur adipisicing elit, sed do eiusmod tempor incididunt labore et dolore magna aliqua enim minim veniam quis nostrud exercitation ulla mco laboris nisi ut aliquip ex ea commodo consequat. duis aute irure dolor in reprehenderit in voluptate.',
    features: [
      'Build fully-fledged websites and web apps for your business.',
      'After the course you will be able to build ANY website you want.',
      'You will master both front and back-end development technologies.'
    ], 
    social_links:[
      {link: 'http://facebook.com', target: '_blank', icon: 'icon-facebook'},
      {link: 'http://twitter.com', target: '_blank', icon: 'icon-twitter'},
      {link: 'https://www.linkedin.com/', target: '_blank', icon: 'icon-linkedin2'},
      {link: 'https://www.youtube.com/', target: '_blank', icon: 'icon-youtube'}
    ],
    language: 'German',
    certificate: 'yes',
    videoId: 'PICj5tr9hcc',
    course_desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor inc idid unt ut labore et dolore magna aliqua enim ad minim veniam, quis nostrud exerec tation ullamco laboris nis aliquip commodo consequat duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur enim ipsam.',
    course_desc_2: 'Excepteur sint occaecat cupidatat non proident sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium totam rem aperiam.',
    learn_list: ['Professional Japanese cooking from beginners to experts','Will be able to cook authentic Italian recipes in their own kitchen','Understand the HOW of cooking, before thinking of the WHAT to cook.'],
    course_desc_3: 'Consectetur adipisicing elit, sed do eiusmod tempor inc idid unt ut labore et dolore magna aliqua enim ad minim veniam quis nostrud exerec tation ullamco laboris nis aliquip commodo consequat duis aute irure dolor.',
    curriculum_desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor inc idid unt ut labore et dolore magna aliqua enim ad minim veniam, quis nostrud exerec tation ullamco laboris nis aliquip commodo consequat.',
    course_lessons: [
      {
        title: 'Week 1-4',
        text: 'Advanced story telling techniques for writers: Personas, Characters & Plots',
        lessons: [
          { title: 'Introduction', icon: 'icon-68' },
          { title: 'Course Overview', icon: 'icon-68' },
          { title: 'Course Overview', badge_list: true, question: 0, minutes: 10 },
          { title: 'Course Exercise / Reference Files', icon: 'icon-68' },
          { title: 'Code Editor Installation (Optional if you have one)', icon: 'icon-68' },
          { title: ' Embedding PHP in HTML', icon: 'icon-68' },
        ]
      },
      {
        title: 'Week 5-8',
        text: 'Advanced story telling techniques for writers: Personas, Characters & Plots',
        lessons: [
          { title: 'Defining Functions', icon: 'icon-68' },
          { title: 'Function Parameters', icon: 'icon-68' },
          { title: ' Return Values From Functions', badge_list: true, question: 2, minutes: 15 },
          { title: ' Global Variable and Scope', icon: 'icon-68' },
          { title: 'Newer Way of creating a Constant', icon: 'icon-68' },
          { title: 'Constants', icon: 'icon-68' },
        ]
      },
    ],
    reviews:[
      {img:'/assets/images/blog/comment-01.jpg',rating:5,name:'Haley Bennet',date:'Oct 10, 2021',desc:'Lorem ipsum dolor sit amet, consectetur adipisicing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'},
      {img:'/assets/images/blog/comment-02.jpg',rating:5,name:'Simon Baker',date:'Oct 15, 2021',desc:'Lorem ipsum dolor sit amet, consectetur adipisicing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'},
      {img:'/assets/images/blog/comment-03.jpg',rating:5,name:'Richard Gere',date:'Oct 19, 2021',desc:'Lorem ipsum dolor sit amet, consectetur adipisicing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'},
    ]
  },
 // kindergarten course 
  {
    id: 26,
    kindergarten_course:true,
    duration: '5 Weeks',
    course_outline:'Online + Onsite',
    img: 'course-26.jpg',
    bg_color:'bg-color-extra02',
    hours: 12,
    level: 'Drawing',
    title: 'Powerful Calming Techniques For Kids and Teens',
    rating: '4.5',
    rating_count: 40,
    course_price: '40',
    lesson: 25,
    student: 45,
    category:'Art & Design',
    short_desc: 'Lorem ipsum dolor sit amet consectur elit sed eiusmod ex tempor incididunt labore dolore magna.',
    instructor: 'Jane Seymour',
    instructor_img:'team-01.webp',
    instructor_title:'Founder & CEO',
    instructor_desc:'Consectetur adipisicing elit, sed do eiusmod tempor incididunt labore et dolore magna aliqua enim minim veniam quis nostrud exercitation ulla mco laboris nisi ut aliquip ex ea commodo consequat. duis aute irure dolor in reprehenderit in voluptate.',
    features: [
      'Build fully-fledged websites and web apps for your business.',
      'After the course you will be able to build ANY website you want.',
      'You will master both front and back-end development technologies.'
    ], 
    social_links:[
      {link: 'http://facebook.com', target: '_blank', icon: 'icon-facebook'},
      {link: 'http://twitter.com', target: '_blank', icon: 'icon-twitter'},
      {link: 'https://www.linkedin.com/', target: '_blank', icon: 'icon-linkedin2'},
      {link: 'https://www.youtube.com/', target: '_blank', icon: 'icon-youtube'}
    ],
    language: 'English',
    certificate: 'yes',
    videoId: 'PICj5tr9hcc',
    course_desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor inc idid unt ut labore et dolore magna aliqua enim ad minim veniam, quis nostrud exerec tation ullamco laboris nis aliquip commodo consequat duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur enim ipsam.',
    course_desc_2: 'Excepteur sint occaecat cupidatat non proident sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium totam rem aperiam.',
    learn_list: ['Professional Japanese cooking from beginners to experts','Will be able to cook authentic Italian recipes in their own kitchen','Understand the HOW of cooking, before thinking of the WHAT to cook.'],
    course_desc_3: 'Consectetur adipisicing elit, sed do eiusmod tempor inc idid unt ut labore et dolore magna aliqua enim ad minim veniam quis nostrud exerec tation ullamco laboris nis aliquip commodo consequat duis aute irure dolor.',
    curriculum_desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor inc idid unt ut labore et dolore magna aliqua enim ad minim veniam, quis nostrud exerec tation ullamco laboris nis aliquip commodo consequat.',
    course_lessons: [
      {
        title: 'Week 1-4',
        text: 'Advanced story telling techniques for writers: Personas, Characters & Plots',
        lessons: [
          { title: 'Introduction', icon: 'icon-68' },
          { title: 'Course Overview', icon: 'icon-68' },
          { title: 'Course Overview', badge_list: true, question: 0, minutes: 10 },
          { title: 'Course Exercise / Reference Files', icon: 'icon-68' },
          { title: 'Code Editor Installation (Optional if you have one)', icon: 'icon-68' },
          { title: ' Embedding PHP in HTML', icon: 'icon-68' },
        ]
      },
      {
        title: 'Week 5-8',
        text: 'Advanced story telling techniques for writers: Personas, Characters & Plots',
        lessons: [
          { title: 'Defining Functions', icon: 'icon-68' },
          { title: 'Function Parameters', icon: 'icon-68' },
          { title: ' Return Values From Functions', badge_list: true, question: 2, minutes: 15 },
          { title: ' Global Variable and Scope', icon: 'icon-68' },
          { title: 'Newer Way of creating a Constant', icon: 'icon-68' },
          { title: 'Constants', icon: 'icon-68' },
        ]
      },
    ],
    reviews:[
      {img:'/assets/images/blog/comment-01.jpg',rating:5,name:'Haley Bennet',date:'Oct 10, 2021',desc:'Lorem ipsum dolor sit amet, consectetur adipisicing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'},
      {img:'/assets/images/blog/comment-02.jpg',rating:5,name:'Simon Baker',date:'Oct 15, 2021',desc:'Lorem ipsum dolor sit amet, consectetur adipisicing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'},
      {img:'/assets/images/blog/comment-03.jpg',rating:5,name:'Richard Gere',date:'Oct 19, 2021',desc:'Lorem ipsum dolor sit amet, consectetur adipisicing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'},
    ]
  },
  {
    id: 27,
    kindergarten_course:true,
    duration: '12 Weeks',
    course_outline:'Online + Onsite',
    img: 'course-27.jpg',
    bg_color:'bg-color-primary',
    hours: 41,
    level: 'Parenting',
    title: 'Parenting Skills to Raise Responsible Children',
    rating: '5',
    rating_count: 42,
    course_price: '50',
    lesson: 28,
    student: 50,
    category:'Development',
    short_desc: 'Lorem ipsum dolor sit amet consectur elit sed eiusmod ex tempor incididunt labore dolore magna.',
    instructor: 'Connie Comerford',
    instructor_img:'team-06.webp',
    instructor_title:'Founder & CEO',
    instructor_desc:'Consectetur adipisicing elit, sed do eiusmod tempor incididunt labore et dolore magna aliqua enim minim veniam quis nostrud exercitation ulla mco laboris nisi ut aliquip ex ea commodo consequat. duis aute irure dolor in reprehenderit in voluptate.',
    features: [
      'Build fully-fledged websites and web apps for your business.',
      'After the course you will be able to build ANY website you want.',
      'You will master both front and back-end development technologies.'
    ], 
    social_links:[
      {link: 'http://facebook.com', target: '_blank', icon: 'icon-facebook'},
      {link: 'http://twitter.com', target: '_blank', icon: 'icon-twitter'},
      {link: 'https://www.linkedin.com/', target: '_blank', icon: 'icon-linkedin2'},
      {link: 'https://www.youtube.com/', target: '_blank', icon: 'icon-youtube'}
    ],
    language: 'English',
    certificate: 'yes',
    videoId: 'PICj5tr9hcc',
    course_desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor inc idid unt ut labore et dolore magna aliqua enim ad minim veniam, quis nostrud exerec tation ullamco laboris nis aliquip commodo consequat duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur enim ipsam.',
    course_desc_2: 'Excepteur sint occaecat cupidatat non proident sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium totam rem aperiam.',
    learn_list: ['Professional Japanese cooking from beginners to experts','Will be able to cook authentic Italian recipes in their own kitchen','Understand the HOW of cooking, before thinking of the WHAT to cook.'],
    course_desc_3: 'Consectetur adipisicing elit, sed do eiusmod tempor inc idid unt ut labore et dolore magna aliqua enim ad minim veniam quis nostrud exerec tation ullamco laboris nis aliquip commodo consequat duis aute irure dolor.',
    curriculum_desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor inc idid unt ut labore et dolore magna aliqua enim ad minim veniam, quis nostrud exerec tation ullamco laboris nis aliquip commodo consequat.',
    course_lessons: [
      {
        title: 'Week 1-4',
        text: 'Advanced story telling techniques for writers: Personas, Characters & Plots',
        lessons: [
          { title: 'Introduction', icon: 'icon-68' },
          { title: 'Course Overview', icon: 'icon-68' },
          { title: 'Course Overview', badge_list: true, question: 0, minutes: 10 },
          { title: 'Course Exercise / Reference Files', icon: 'icon-68' },
          { title: 'Code Editor Installation (Optional if you have one)', icon: 'icon-68' },
          { title: ' Embedding PHP in HTML', icon: 'icon-68' },
        ]
      },
      {
        title: 'Week 5-8',
        text: 'Advanced story telling techniques for writers: Personas, Characters & Plots',
        lessons: [
          { title: 'Defining Functions', icon: 'icon-68' },
          { title: 'Function Parameters', icon: 'icon-68' },
          { title: ' Return Values From Functions', badge_list: true, question: 2, minutes: 15 },
          { title: ' Global Variable and Scope', icon: 'icon-68' },
          { title: 'Newer Way of creating a Constant', icon: 'icon-68' },
          { title: 'Constants', icon: 'icon-68' },
        ]
      },
    ],
    reviews:[
      {img:'/assets/images/blog/comment-01.jpg',rating:5,name:'Haley Bennet',date:'Oct 10, 2021',desc:'Lorem ipsum dolor sit amet, consectetur adipisicing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'},
      {img:'/assets/images/blog/comment-02.jpg',rating:5,name:'Simon Baker',date:'Oct 15, 2021',desc:'Lorem ipsum dolor sit amet, consectetur adipisicing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'},
      {img:'/assets/images/blog/comment-03.jpg',rating:5,name:'Richard Gere',date:'Oct 19, 2021',desc:'Lorem ipsum dolor sit amet, consectetur adipisicing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'},
    ]
  },
  {
    id: 28,
    kindergarten_course:true,
    duration: '5 Weeks',
    course_outline:'Onsite Only',
    img: 'course-28.jpg',
    bg_color:'bg-color-secondary',
    hours: 12,
    level: 'Drawing',
    title: 'The Ultimate Drawing Course - Beginner to Advanced',
    rating: '4.5',
    rating_count: 40,
    course_price: '60',
    lesson: 25,
    student: 45,
    category:'Art & Design',
    short_desc: 'Lorem ipsum dolor sit amet consectur elit sed eiusmod ex tempor incididunt labore dolore magna.',
    instructor: 'Connie Comerford',
    instructor_img:'team-06.webp',
    instructor_title:'Founder & CEO',
    instructor_desc:'Consectetur adipisicing elit, sed do eiusmod tempor incididunt labore et dolore magna aliqua enim minim veniam quis nostrud exercitation ulla mco laboris nisi ut aliquip ex ea commodo consequat. duis aute irure dolor in reprehenderit in voluptate.',
    features: [
      'Build fully-fledged websites and web apps for your business.',
      'After the course you will be able to build ANY website you want.',
      'You will master both front and back-end development technologies.'
    ], 
    social_links:[
      {link: 'http://facebook.com', target: '_blank', icon: 'icon-facebook'},
      {link: 'http://twitter.com', target: '_blank', icon: 'icon-twitter'},
      {link: 'https://www.linkedin.com/', target: '_blank', icon: 'icon-linkedin2'},
      {link: 'https://www.youtube.com/', target: '_blank', icon: 'icon-youtube'}
    ],
    language: 'English',
    certificate: 'yes',
    videoId: 'PICj5tr9hcc',
    course_desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor inc idid unt ut labore et dolore magna aliqua enim ad minim veniam, quis nostrud exerec tation ullamco laboris nis aliquip commodo consequat duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur enim ipsam.',
    course_desc_2: 'Excepteur sint occaecat cupidatat non proident sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium totam rem aperiam.',
    learn_list: ['Professional Japanese cooking from beginners to experts','Will be able to cook authentic Italian recipes in their own kitchen','Understand the HOW of cooking, before thinking of the WHAT to cook.'],
    course_desc_3: 'Consectetur adipisicing elit, sed do eiusmod tempor inc idid unt ut labore et dolore magna aliqua enim ad minim veniam quis nostrud exerec tation ullamco laboris nis aliquip commodo consequat duis aute irure dolor.',
    curriculum_desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor inc idid unt ut labore et dolore magna aliqua enim ad minim veniam, quis nostrud exerec tation ullamco laboris nis aliquip commodo consequat.',
    course_lessons: [
      {
        title: 'Week 1-4',
        text: 'Advanced story telling techniques for writers: Personas, Characters & Plots',
        lessons: [
          { title: 'Introduction', icon: 'icon-68' },
          { title: 'Course Overview', icon: 'icon-68' },
          { title: 'Course Overview', badge_list: true, question: 0, minutes: 10 },
          { title: 'Course Exercise / Reference Files', icon: 'icon-68' },
          { title: 'Code Editor Installation (Optional if you have one)', icon: 'icon-68' },
          { title: ' Embedding PHP in HTML', icon: 'icon-68' },
        ]
      },
      {
        title: 'Week 5-8',
        text: 'Advanced story telling techniques for writers: Personas, Characters & Plots',
        lessons: [
          { title: 'Defining Functions', icon: 'icon-68' },
          { title: 'Function Parameters', icon: 'icon-68' },
          { title: ' Return Values From Functions', badge_list: true, question: 2, minutes: 15 },
          { title: ' Global Variable and Scope', icon: 'icon-68' },
          { title: 'Newer Way of creating a Constant', icon: 'icon-68' },
          { title: 'Constants', icon: 'icon-68' },
        ]
      },
    ],
    reviews:[
      {img:'/assets/images/blog/comment-01.jpg',rating:5,name:'Haley Bennet',date:'Oct 10, 2021',desc:'Lorem ipsum dolor sit amet, consectetur adipisicing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'},
      {img:'/assets/images/blog/comment-02.jpg',rating:5,name:'Simon Baker',date:'Oct 15, 2021',desc:'Lorem ipsum dolor sit amet, consectetur adipisicing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'},
      {img:'/assets/images/blog/comment-03.jpg',rating:5,name:'Richard Gere',date:'Oct 19, 2021',desc:'Lorem ipsum dolor sit amet, consectetur adipisicing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'},
    ]
  },
  {
    id: 29,
    kindergarten_course:true,
    duration: '12 Weeks',
    course_outline:'Online + Onsite',
    img: 'course-29.jpg',
    bg_color:'bg-color-extra02',
    hours: 41,
    level: 'Parenting',
    title: 'Public Speaking for Kids (& Parents) Kids Can Speak!',
    rating: '5',
    rating_count: 42,
    course_price: '70',
    lesson: 28,
    student: 50,
    category:'Development',
    short_desc: 'Lorem ipsum dolor sit amet consectur elit sed eiusmod ex tempor incididunt labore dolore magna.',
    instructor: 'Connie Comerford',
    instructor_img:'team-06.webp',
    instructor_title:'Founder & CEO',
    instructor_desc:'Consectetur adipisicing elit, sed do eiusmod tempor incididunt labore et dolore magna aliqua enim minim veniam quis nostrud exercitation ulla mco laboris nisi ut aliquip ex ea commodo consequat. duis aute irure dolor in reprehenderit in voluptate.',
    features: [
      'Build fully-fledged websites and web apps for your business.',
      'After the course you will be able to build ANY website you want.',
      'You will master both front and back-end development technologies.'
    ], 
    social_links:[
      {link: 'http://facebook.com', target: '_blank', icon: 'icon-facebook'},
      {link: 'http://twitter.com', target: '_blank', icon: 'icon-twitter'},
      {link: 'https://www.linkedin.com/', target: '_blank', icon: 'icon-linkedin2'},
      {link: 'https://www.youtube.com/', target: '_blank', icon: 'icon-youtube'}
    ],
    language: 'English',
    certificate: 'yes',
    videoId: 'PICj5tr9hcc',
    course_desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor inc idid unt ut labore et dolore magna aliqua enim ad minim veniam, quis nostrud exerec tation ullamco laboris nis aliquip commodo consequat duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur enim ipsam.',
    course_desc_2: 'Excepteur sint occaecat cupidatat non proident sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium totam rem aperiam.',
    learn_list: ['Professional Japanese cooking from beginners to experts','Will be able to cook authentic Italian recipes in their own kitchen','Understand the HOW of cooking, before thinking of the WHAT to cook.'],
    course_desc_3: 'Consectetur adipisicing elit, sed do eiusmod tempor inc idid unt ut labore et dolore magna aliqua enim ad minim veniam quis nostrud exerec tation ullamco laboris nis aliquip commodo consequat duis aute irure dolor.',
    curriculum_desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor inc idid unt ut labore et dolore magna aliqua enim ad minim veniam, quis nostrud exerec tation ullamco laboris nis aliquip commodo consequat.',
    course_lessons: [
      {
        title: 'Week 1-4',
        text: 'Advanced story telling techniques for writers: Personas, Characters & Plots',
        lessons: [
          { title: 'Introduction', icon: 'icon-68' },
          { title: 'Course Overview', icon: 'icon-68' },
          { title: 'Course Overview', badge_list: true, question: 0, minutes: 10 },
          { title: 'Course Exercise / Reference Files', icon: 'icon-68' },
          { title: 'Code Editor Installation (Optional if you have one)', icon: 'icon-68' },
          { title: ' Embedding PHP in HTML', icon: 'icon-68' },
        ]
      },
      {
        title: 'Week 5-8',
        text: 'Advanced story telling techniques for writers: Personas, Characters & Plots',
        lessons: [
          { title: 'Defining Functions', icon: 'icon-68' },
          { title: 'Function Parameters', icon: 'icon-68' },
          { title: ' Return Values From Functions', badge_list: true, question: 2, minutes: 15 },
          { title: ' Global Variable and Scope', icon: 'icon-68' },
          { title: 'Newer Way of creating a Constant', icon: 'icon-68' },
          { title: 'Constants', icon: 'icon-68' },
        ]
      },
    ],
    reviews:[
      {img:'/assets/images/blog/comment-01.jpg',rating:5,name:'Haley Bennet',date:'Oct 10, 2021',desc:'Lorem ipsum dolor sit amet, consectetur adipisicing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'},
      {img:'/assets/images/blog/comment-02.jpg',rating:5,name:'Simon Baker',date:'Oct 15, 2021',desc:'Lorem ipsum dolor sit amet, consectetur adipisicing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'},
      {img:'/assets/images/blog/comment-03.jpg',rating:5,name:'Richard Gere',date:'Oct 19, 2021',desc:'Lorem ipsum dolor sit amet, consectetur adipisicing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'},
    ]
  },
  {
    id: 30,
    kindergarten_course:true,
    duration: '5 Weeks',
    course_outline:'Online Only',
    img: 'course-30.jpg',
    bg_color:'bg-color-primary',
    hours: 12,
    level: 'Drawing',
    title: 'Real-World Programming for Kids with Python',
    rating: '4.5',
    rating_count: 40,
    course_price: '80',
    lesson: 25,
    student: 45,
    category:'Art & Design',
    short_desc: 'Lorem ipsum dolor sit amet consectur elit sed eiusmod ex tempor incididunt labore dolore magna.',
    instructor: 'Susan White',
    instructor_img:'team-05.webp',
    instructor_title:'Founder & CEO',
    instructor_desc:'Consectetur adipisicing elit, sed do eiusmod tempor incididunt labore et dolore magna aliqua enim minim veniam quis nostrud exercitation ulla mco laboris nisi ut aliquip ex ea commodo consequat. duis aute irure dolor in reprehenderit in voluptate.',
    features: [
      'Build fully-fledged websites and web apps for your business.',
      'After the course you will be able to build ANY website you want.',
      'You will master both front and back-end development technologies.'
    ], 
    social_links:[
      {link: 'http://facebook.com', target: '_blank', icon: 'icon-facebook'},
      {link: 'http://twitter.com', target: '_blank', icon: 'icon-twitter'},
      {link: 'https://www.linkedin.com/', target: '_blank', icon: 'icon-linkedin2'},
      {link: 'https://www.youtube.com/', target: '_blank', icon: 'icon-youtube'}
    ],
    language: 'English',
    certificate: 'yes',
    videoId: 'PICj5tr9hcc',
    course_desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor inc idid unt ut labore et dolore magna aliqua enim ad minim veniam, quis nostrud exerec tation ullamco laboris nis aliquip commodo consequat duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur enim ipsam.',
    course_desc_2: 'Excepteur sint occaecat cupidatat non proident sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium totam rem aperiam.',
    learn_list: ['Professional Japanese cooking from beginners to experts','Will be able to cook authentic Italian recipes in their own kitchen','Understand the HOW of cooking, before thinking of the WHAT to cook.'],
    course_desc_3: 'Consectetur adipisicing elit, sed do eiusmod tempor inc idid unt ut labore et dolore magna aliqua enim ad minim veniam quis nostrud exerec tation ullamco laboris nis aliquip commodo consequat duis aute irure dolor.',
    curriculum_desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor inc idid unt ut labore et dolore magna aliqua enim ad minim veniam, quis nostrud exerec tation ullamco laboris nis aliquip commodo consequat.',
    course_lessons: [
      {
        title: 'Week 1-4',
        text: 'Advanced story telling techniques for writers: Personas, Characters & Plots',
        lessons: [
          { title: 'Introduction', icon: 'icon-68' },
          { title: 'Course Overview', icon: 'icon-68' },
          { title: 'Course Overview', badge_list: true, question: 0, minutes: 10 },
          { title: 'Course Exercise / Reference Files', icon: 'icon-68' },
          { title: 'Code Editor Installation (Optional if you have one)', icon: 'icon-68' },
          { title: ' Embedding PHP in HTML', icon: 'icon-68' },
        ]
      },
      {
        title: 'Week 5-8',
        text: 'Advanced story telling techniques for writers: Personas, Characters & Plots',
        lessons: [
          { title: 'Defining Functions', icon: 'icon-68' },
          { title: 'Function Parameters', icon: 'icon-68' },
          { title: ' Return Values From Functions', badge_list: true, question: 2, minutes: 15 },
          { title: ' Global Variable and Scope', icon: 'icon-68' },
          { title: 'Newer Way of creating a Constant', icon: 'icon-68' },
          { title: 'Constants', icon: 'icon-68' },
        ]
      },
    ],
    reviews:[
      {img:'/assets/images/blog/comment-01.jpg',rating:5,name:'Haley Bennet',date:'Oct 10, 2021',desc:'Lorem ipsum dolor sit amet, consectetur adipisicing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'},
      {img:'/assets/images/blog/comment-02.jpg',rating:5,name:'Simon Baker',date:'Oct 15, 2021',desc:'Lorem ipsum dolor sit amet, consectetur adipisicing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'},
      {img:'/assets/images/blog/comment-03.jpg',rating:5,name:'Richard Gere',date:'Oct 19, 2021',desc:'Lorem ipsum dolor sit amet, consectetur adipisicing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'},
    ]
  },
  {
    id: 31,
    kindergarten_course:true,
    duration: '12 Weeks',
    course_outline:'Online + Onsite',
    img: 'course-31.jpg',
    bg_color:'bg-color-secondary',
    hours: 41,
    level: 'Parenting',
    title: 'How to Get Kids to Eat Healthy',
    rating: '5',
    rating_count: 42,
    course_price: '90',
    lesson: 28,
    student: 50,
    category:'Development',
    short_desc: 'Lorem ipsum dolor sit amet consectur elit sed eiusmod ex tempor incididunt labore dolore magna.',
    instructor: 'Jane Seymour',
    instructor_img:'team-01.webp',
    instructor_title:'Founder & CEO',
    instructor_desc:'Consectetur adipisicing elit, sed do eiusmod tempor incididunt labore et dolore magna aliqua enim minim veniam quis nostrud exercitation ulla mco laboris nisi ut aliquip ex ea commodo consequat. duis aute irure dolor in reprehenderit in voluptate.',
    features: [
      'Build fully-fledged websites and web apps for your business.',
      'After the course you will be able to build ANY website you want.',
      'You will master both front and back-end development technologies.'
    ], 
    social_links:[
      {link: 'http://facebook.com', target: '_blank', icon: 'icon-facebook'},
      {link: 'http://twitter.com', target: '_blank', icon: 'icon-twitter'},
      {link: 'https://www.linkedin.com/', target: '_blank', icon: 'icon-linkedin2'},
      {link: 'https://www.youtube.com/', target: '_blank', icon: 'icon-youtube'}
    ],
    language: 'English',
    certificate: 'yes',
    videoId: 'PICj5tr9hcc',
    course_desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor inc idid unt ut labore et dolore magna aliqua enim ad minim veniam, quis nostrud exerec tation ullamco laboris nis aliquip commodo consequat duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur enim ipsam.',
    course_desc_2: 'Excepteur sint occaecat cupidatat non proident sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium totam rem aperiam.',
    learn_list: ['Professional Japanese cooking from beginners to experts','Will be able to cook authentic Italian recipes in their own kitchen','Understand the HOW of cooking, before thinking of the WHAT to cook.'],
    course_desc_3: 'Consectetur adipisicing elit, sed do eiusmod tempor inc idid unt ut labore et dolore magna aliqua enim ad minim veniam quis nostrud exerec tation ullamco laboris nis aliquip commodo consequat duis aute irure dolor.',
    curriculum_desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor inc idid unt ut labore et dolore magna aliqua enim ad minim veniam, quis nostrud exerec tation ullamco laboris nis aliquip commodo consequat.',
    course_lessons: [
      {
        title: 'Week 1-4',
        text: 'Advanced story telling techniques for writers: Personas, Characters & Plots',
        lessons: [
          { title: 'Introduction', icon: 'icon-68' },
          { title: 'Course Overview', icon: 'icon-68' },
          { title: 'Course Overview', badge_list: true, question: 0, minutes: 10 },
          { title: 'Course Exercise / Reference Files', icon: 'icon-68' },
          { title: 'Code Editor Installation (Optional if you have one)', icon: 'icon-68' },
          { title: ' Embedding PHP in HTML', icon: 'icon-68' },
        ]
      },
      {
        title: 'Week 5-8',
        text: 'Advanced story telling techniques for writers: Personas, Characters & Plots',
        lessons: [
          { title: 'Defining Functions', icon: 'icon-68' },
          { title: 'Function Parameters', icon: 'icon-68' },
          { title: ' Return Values From Functions', badge_list: true, question: 2, minutes: 15 },
          { title: ' Global Variable and Scope', icon: 'icon-68' },
          { title: 'Newer Way of creating a Constant', icon: 'icon-68' },
          { title: 'Constants', icon: 'icon-68' },
        ]
      },
    ],
    reviews:[
      {img:'/assets/images/blog/comment-01.jpg',rating:5,name:'Haley Bennet',date:'Oct 10, 2021',desc:'Lorem ipsum dolor sit amet, consectetur adipisicing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'},
      {img:'/assets/images/blog/comment-02.jpg',rating:5,name:'Simon Baker',date:'Oct 15, 2021',desc:'Lorem ipsum dolor sit amet, consectetur adipisicing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'},
      {img:'/assets/images/blog/comment-03.jpg',rating:5,name:'Richard Gere',date:'Oct 19, 2021',desc:'Lorem ipsum dolor sit amet, consectetur adipisicing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'},
    ]
  }
]

export default course_data;