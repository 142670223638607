export const partner_data = {
    pre_title: "Our Partners",
    title: "Meet our business Partners",
    text: "We thrive on collaborative problem-solving. Partnering with like-minded organizations, we deliver impactful solutions.",
    brands: [
        { country:'Finland', dateSigned:'1', month:'Jan', img: '3dbear_intro.png', year:'24', id:1, descrip:'We offer customized service solutions and products that enable more authentic and personalized learning.', logo: "3DBear-logo.jpg", name: "3D Bear",website:"https://www.3dbear.io" },
      { country:'Finland', dateSigned:'1', month:'Jan', img: 'sopu-intro.png', year:'24', id:2, descrip:'We provide world-class negotiation skills programs for people from all around the world. Our goal is to support students to develop communication skills that are needed throughout life.', logo: "Sopu_Academy_black.png", name: "Sopu Academy",website:"https://sopuacademy.com" },
      { country:'Finland', dateSigned:'23', month:'Jan', img: 'phoenix-intro.png', year:'24', id:3, descrip:'We are a coaching company passionate about social change, with a mission to mainstream the power of coaching to achieve positive change in the world.', logo: "Phoenix-logoB-02.png", name: "Phoenix Coaching" ,website:"www.phoenixcoaching.fi"},
      { country:'Finland', dateSigned:'27', month:'Jan', img: 'cogknit-intro.png', year:'24', id:4, descrip:'Connects Companies, Academics, Mentors, Students and allows various projects to be utilised in innovations and ensure future skills for learners', logo: "Cogknit_logo.png", name: "Cogknit " ,website:"https://www.cogknit.io"},
      { country:'Tanzania', dateSigned:'19', month:'Feb', img: 'plantbio_intro.png', year:'24', id:5, descrip:'Plant Biodefenders', logo: "plantbio_logo.png", name: "Plant Biodefenders Limited" ,website:"https://sopuacademy.com" },
      { country:'Tanzania', dateSigned:'29', month:'Feb', img: 'kinov-intro.png', year:'24', id:6, descrip:'Our partner in Tanzania', logo: "kinov-logo.png", name: "KINOVS Promotions" ,website:"https://www.instagram.com/kinovspromotions?igsh=OWU5M29rdW8yaGtj&utm_source=qr"},
      { country:'Tanzania', dateSigned:'29', month:'Feb', img: 'BF-intro.png', year:'24', id:7, descrip:'B&F Global Company Limited is the Tanzanian Established Company with International reputation focus on providing Consultancy, Training, Counselling and Advisory Services in the areas of Transportation Infrastructures, Telecommunication Infrastructures together with Energy & Water Infrastructures.', logo: "BF-logo.png", name: "B&F Global Company Limited" ,website:"https://dev.ujuzi.io/champions"},
      { country:'Country Name', dateSigned:'30', month:'Sep', img: 'event-07.jpg', year:'24', id:8, descrip:'Best learining institution in the application of modern day Stem, Aim at forstering Stem knowledge', logo: "brand-07.png", name: "institution 1" ,website:"https://dev.ujuzi.io/champions"},
      { country:'Country Name', dateSigned:'30', month:'Sep', img: 'event-08.jpg', year:'24', id:9, descrip:'Best learining institution in the application of modern day Stem, Aim at forstering Stem knowledge', logo: "brand-08.png", name: "institution 1" ,website:"https://dev.ujuzi.io/champions"},
    ],
  };