
// import SEO from '../../../seo';
import About from '../../../WhoIsUjuzi/AboutUjuzi';

const AboutUjuzi = () => {
    return (
        <>
            {/* <SEO pageTitle={'About Ujuzi'} /> */}
            <About />
        </>
    )
}

export default AboutUjuzi;